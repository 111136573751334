import React from "react";
import NavBar from "../navbar/navbar";
import StickyFooter from "../footer";
import useStyles from "../../css/const";

/**
 * Default layout to be used with pages
 * Has navbar on top and sticky footer on the bottom
 */
export default function DefaultLayout(props) {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <NavBar />
      <div
        style={{
          width: "95%",
          maxWidth: "1150px",
          margin: "0 auto",
          paddingTop: "40px",
          marginBottom: "20px",
        }}
      >
        {props.children}
      </div>
      <StickyFooter />
    </div>
  );
}
