import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import DoneIcon from "@mui/icons-material/Done";

import {
  Paper,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import countries from "../../json/countries.json";
import { useEffect } from "react";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import UserService from "../../services/api/user-service";
import useTracker from "../../hooks/use-tracker";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  successPaper: {
    backgroundColor: theme.palette.success.light,
    width: "50%",
    margin: "0 auto",
    marginTop: "30px",
  },
}));

export default function SignUp() {
  useDocTitle("Sign Up");
  useTracker();
  const classes = useStyles();

  // Non-mandatory fields
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [institution, setInstitution] = React.useState(null);
  const [newsCheckBox, setNewsCheckbox] = React.useState(false);

  // Mandatory fields
  const [email, setEmail] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [confirm, setConfirm] = React.useState(null);

  // Status
  const [emailStatus, setEmailStatus] = React.useState(null);
  const [usernameStatus, setUsernameStatus] = React.useState(null);
  const [countryStatus, setCountryStatus] = React.useState(null);
  const [passwordStatus, setPasswordStatus] = React.useState(null);
  const [confirmStatus, setConfirmStatus] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  // Checkers
  useEffect(() => {
    // TODO: implement timeout to check this when user finishes typing.
    const msg = "Password confirmation do not match the password!";
    const status = confirm !== password && confirm !== null ? msg : null;
    setConfirmStatus(status);
  }, [password, confirm]);

  const isEmpty = (fieldName, fieldVar, statusFunc) => {
    var msg = " field must be filled!";
    if (fieldVar === null) {
      statusFunc(fieldName + msg);
      return true;
    } else {
      statusFunc(null);
      return false;
    }
  };
  const allMandatoryFieldsFilled = () => {
    const emailIsEmpty = isEmpty("Email", email, setEmailStatus);
    const usernameIsEmpty = isEmpty("Username", username, setUsernameStatus);
    const countryIsEmpty = isEmpty("Country", country, setCountryStatus);
    const passwordIsEmpty = isEmpty("Password", password, setPasswordStatus);
    const confirmIsEmpty = isEmpty("Confirm", confirm, setConfirmStatus);
    return (
      !emailIsEmpty &&
      !usernameIsEmpty &&
      !countryIsEmpty &&
      !passwordIsEmpty &&
      !confirmIsEmpty
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (allMandatoryFieldsFilled()) {
      setButtonDisabled(true);
      UserService.create(
        email,
        username,
        password,
        confirm,
        firstName,
        lastName,
        country,
        institution
      )
        .then((response) => {
          setSuccess(true);
        })
        .catch((error) => {
          setSuccess(false);
          setButtonDisabled(false);
          if ("email" in error.data) setEmailStatus(error.data.email[0]);

          if ("password" in error.data)
            setPasswordStatus(error.data.password[0]);

          if ("password_confirm" in error.data)
            setConfirmStatus(error.data.password_confirm[0]);

          if ("username" in error.data)
            setUsernameStatus(error.data.username[0]);
        });
    }
  };

  return (
    <DefaultLayout>
      {!success ? (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>

            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel required id="select-country-label">
                      Country
                    </InputLabel>
                    <Select
                      variant="standard"
                      error={countryStatus}
                      value={country}
                      onChange={(event) => setCountry(event.target.value)}>
                      {countries.map((x) => (
                        <MenuItem value={x.code}> {x.name} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={institution}
                    onChange={(event) => setInstitution(event.target.value)}
                    variant="outlined"
                    fullWidth
                    id="institution"
                    label="Institution"
                  ></TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={usernameStatus}
                    helperText={usernameStatus ? usernameStatus : null}
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    autoComplete="username"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={emailStatus}
                    helperText={emailStatus ? emailStatus : null}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={passwordStatus}
                    helperText={passwordStatus ? passwordStatus : null}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={confirmStatus}
                    helperText={confirmStatus ? confirmStatus : null}
                    value={confirm}
                    onChange={(event) => setConfirm(event.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    name="confirm"
                    label="Confirm"
                    type="password"
                    id="confirm"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={newsCheckBox}
                        onChange={() => setNewsCheckbox(!newsCheckBox)}
                        color="primary"
                      />
                    }
                    label="I want to receive emails about future events and other news."
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={buttonDisabled}
              >
                Sign Up
              </Button>

              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="signin" variant="body2" underline="hover">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      ) : (
        <Grid container justifyContent="center" align="center" alignItems="center">
          <Grid item xs={12}>
            <DoneIcon htmlColor="green" style={{ fontSize: "400%" }}></DoneIcon>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Congratulations, you are one step away from being a user of
              CommonRoad!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              To finish up your registration process, click the link in the
              email we have just sent you. If you don't receive an email within
              the next 30 minutes, make sure to check your spam folder or write
              an email to commonroad@lists.lrz.de.
            </Typography>
          </Grid>
        </Grid>
      )}
    </DefaultLayout>
  );
}
