import React from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";

const team = {
  "Prof. Dr.-Ing. Matthias Althoff": {
    email: "mailto:althoff[at]tum.de",
    profile: "https://www6.in.tum.de/en/people/prof-dr-ing-matthias-althoff",
    role: "Professor",
  },

  "Luis Gressenbuch": {
    email: "mailto:luis.gressenbuch[at]tum.de",
    profile: "https://www.in.tum.de/en/i06/people/luis-gressenbuch-msc/",
    role: "Research Assistant",
  },

  "Edmond Irani Liu": {
    email: "mailto:edmond.irani[at]tum.de",
    profile: "https://www6.in.tum.de/en/people/edmond-irani-liu-msc/",
    role: "Research Assistant",
  },

  "Moritz Klischat": {
    email: "mailto:moritz.klischat[at]tum.de",
    profile: "https://www6.in.tum.de/en/people/moritz-klischat-msc/",
    role: "Research Assistant",
  },

  "Eivind Meyer": {
    email: "mailto:eivind.meyer[at]tum.de",
    profile: "https://www.in.tum.de/i06/people/eivind-meyer-msc/",
    role: "Research Assistant",
  },

  "Sebastian Maierhofer": {
    email: "mailto:sebastian.maierhofer[at]tum.de",
    profile: "https://www.in.tum.de/i06/people/sebastian-maierhofer-msc/",
    role: "Research Assistant",
  },

  "Xiao Wang": {
    email: "mailto:xiao.wang[at]tum.de",
    profile: "https://www.in.tum.de/en/i06/people/xiao-wang-msc/",
    role: "Research Assistant",
  },

  "Gerald Würsching": {
    email: "mailto:gerald.wuersching[at]tum.de",
    profile: "https://www.in.tum.de/en/i06/people/gerald-wuersching-msc/",
    role: "Research Assistant",
  },

  "Yuanfei Lin": {
    email: "mailto:yuanfei.lin[at]tum.de",
    profile: "https://www.in.tum.de/i06/people/yuanfei-lin-msc/",
    role: "Research Assistant",
  },

  "Josefine Gaßner": {
    email: "mailto:j.gassner[at]tum.de",
    profile: "https://www.in.tum.de/i06/people/josefine-gassner-msc/",
    role: "Research Assistant",
  },

  "Patrick Halder": {
    email: "mailto:patrick.halder[at]tum.de",
    profile: "https://www.in.tum.de/i06/people/patrick-halder-msc/",
    role: "Research Assistant",
  },
  "Sina Andaç Tan": {
    email: "mailto:andac.tan[at]tum.de",
    profile: "https://www.linkedin.com/in/sina-andaç-tan-00626b136",
    role: "HiWi / Student Assistant",
  },

  "Salih Can Yurtkulu": {
    email: "mailto:salih.yurtkulu[at]tum.de",
    profile: "https://www.linkedin.com/in/salih-can-yurtkulu-aa6239140",
    role: "HiWi / Student Assistant",
  },

  "Barış Cemal Onur": {
    email: "mailto:baris.onur[at]tum.de",
    profile: "https://www.linkedin.com/in/barisonur",
    role: "HiWi / Student Assistant",
  },

  "Hajime Sekiya": {
    email: "mailto:hajime.sekiya[at]tum.de",
    profile: "https://www.linkedin.com/in/hajime-sekiya-8411a0202",
    role: "HiWi / Student Assistant",
  },
};

export default function TeamPage() {
  useDocTitle("Team");
  useTracker();

  return (
    <DefaultLayout>
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Team
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {Object.keys(team).map((key, val) => {
              return (
                <TableRow key={key}>
                  <TableCell>
                    <Typography>{key}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{team[key].role}</Typography>
                  </TableCell>
                  <TableCell>
                    <a href={team[key].profile}>Profile Page</a>
                  </TableCell>
                  <TableCell>
                    <a href={team[key].email.replace("[at]", "@")}>
                      {team[key].email.replace("mailto:", "")}
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DefaultLayout>
  );
}
