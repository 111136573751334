import React from "react";

import { Paper } from "@mui/material";
import DefaultLayout from "../../../components/layouts/default-layout";
import useDocTitle from "../../../hooks/use-doc-title";
import useTracker from "../../../hooks/use-tracker";
import Grid from "@mui/material/Grid";
import { QuickAccessCompetition } from "../../../components/quick-access-competition";
import baseStyle from "../../../css/const";

import CompetitionMarkdown from "./competition-markdown";

export default function CompetitionOrganizers({ year, documentType }) {

    useDocTitle(`Competition ${year} - Organizers`);
    useTracker();

    const classes = baseStyle();

    return (
        <DefaultLayout>
            <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} md={10}>
                    <Paper className={classes.paper}>
                        <CompetitionMarkdown year={year} documentType={documentType} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={2}>
                    <QuickAccessCompetition year={year} />
                </Grid>
            </Grid>
        </DefaultLayout>
    );
}
