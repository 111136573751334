import React from "react";
import axios from "axios";
import Markdown from "react-markdown";
import CodeBlock from "../components/markdown/code-block";
import ToolService from "../services/api/tool-service";

const _markdown = `Here is some JavaScript code:

  ~~~js
  console.log('It works!')
  ~~~`;

export function ExampleCode({ source, ...rest }) {
  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    axios
      .get("http://localhost:8000/api/tool/")
      .then((result) => {
        console.log(result);
        console.log(result.data.results);
        setMarkdown(result.data.results[0].code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(markdown);
  console.log(typeof markdown);
  console.log(typeof _markdown);
  return <Markdown source={markdown} renderers={{ code: CodeBlock }} />;
  // return <div></div>
}

export default function Develop() {
  React.useEffect(() => {
    ToolService.getTools(['id', 'short_title', 'title', 'parent_group'])
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return <div />;
}
