import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import { useHistory } from "react-router-dom";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import AuthenticationService from "../../services/api/authentication-service";
import useTracker from "../../hooks/use-tracker";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}));

export default function SignIn(props) {
  useDocTitle("Login");
  useTracker();

  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [detailError, setDetailError] = React.useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    AuthenticationService.login(email, password)
      .then((response) => {
        if (props.sso) {
          props.sso(true);
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        const errMessages = error.data;

        setDetailError(
          errMessages.hasOwnProperty("detail") ? errMessages.detail : null
        );
        setEmailError(
          errMessages.hasOwnProperty("email") ? errMessages.email[0] : null
        );
        setPasswordError(
          errMessages.hasOwnProperty("password")
            ? errMessages.password[0]
            : null
        );
      });
  };

  return (
    <DefaultLayout>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              error={emailError || detailError}
              helperText={emailError ? emailError : null}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />

            <TextField
              error={passwordError || detailError}
              helperText={passwordError ? passwordError : null}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            {detailError ? (
              <Typography className={classes.errorMessage} variant="body2">
                {detailError}
              </Typography>
            ) : null}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs>
                <Link href="recover" variant="body2" underline="hover">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="signup" variant="body2" underline="hover">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
              <Grid item>
                <Link href="reactivate" variant="body2" underline="hover">
                  Don't receive activation link?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </DefaultLayout>
  );
}
