import React from "react";
import LargeLayout from "../../components/layouts/large-layout";
import NotebookFrame from "../../components/notebook-frame";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import TutorialService from "../../services/api/tutorial-service";

import { useHistory } from "react-router-dom";

export default function Tutorial(props) {
  const history = useHistory();

  const tutorialLink = props.match.params.tutorial;

  if (tutorialLink === undefined) {
    history.replace(history.location.pathname, {
      errorCode: "InvalidURL",
    });
  }

  const [tutorial, setTutorial] = React.useState(null);
  React.useEffect(() => {
    TutorialService.getTutorial(tutorialLink)
      .then((response) => {
        setTutorial(response);
      })
      .catch((error) => {
        history.replace(history.location.pathname, {
          errorCode: "InvalidURL",
        });
      });
  }, [tutorialLink]);

  useDocTitle(tutorial ? tutorial.title : "undefined");
  useTracker();

  return (
    <LargeLayout>
      {tutorial ? (
        <NotebookFrame title={tutorial.title} src={tutorial.jupyter_html} />
      ) : null}
    </LargeLayout>
  );
}
