import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

export default function UserList(props) {
  /*
    expected props:
        - users: [
            {username: str}
        ]
        - Icon: @mui/icons-material component
    */
  const users = props.users ? props.users : [];
  const Icon = props.Icon ? props.Icon : PersonIcon
  return (
    <List dense>
      {users.map((user) => {
        return (
          <ListItem key={user.username}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={user.username} />
          </ListItem>
        );
      })}
    </List>
  );
}
