import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import { TableCell } from "@mui/material";
import CustomStatusBox from './misc/custom-status-box';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ErrorCell(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'error-popper' : undefined;

  return (
    <TableCell style={{padding: "0"}} onMouseEnter={handleClick} onMouseLeave={handleClick}>
      <CustomStatusBox aria-describedby={id} onClick={handleClick} status='error' withIcon={true}/>
      {/* <a aria-describedby={id} onClick={handleClick} style={{textDecorationLine: 'underline'}}>error</a> */}
      <Popper id={id} open={open} placement={'right'} anchorEl={anchorEl}>
        <div className={classes.paper}>{props.children}</div>
      </Popper>
    </TableCell>
  );
}