import React from "react";
import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Logo from "../logo";
import { Button } from "@mui/material";

import UserAccountMenu from "./user-account-menu";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import Hidden from "@mui/material/Hidden";
import getNavigationLinks from "./navbar-links";
import StorageService from "../../services/local-storage/storage-service";
import NavbarListItem from "./navbar-list-item";
import ToolService from "../../services/api/tool-service";
import _ from "lodash";
import NavigationGrid from "./navigation-grid";

const drawerWidth = "25%";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  toolBar: {
    overflowX: "auto",
    overflowY: "auto",
    minHeight: "60px",
    background: theme.palette.primary.dark,
  },

  menuSelect: {
    paddingLeft: "20px",
  },

  menuButton: {
    marginLeft: "5px",
    color: "white",
    outline: "0 !important",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "18px",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },

  drawerButton: {
    marginLeft: "auto",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },

  typoContent: {
    marginBottom: 14,
  },

  divider: {
    marginTop: 10,
    marginBottom: 10,
  },

  menuItem: {
    fontSize: "0.9em",
  },

  a: {
    color: "inherit",
    textDecoration: "none !important",
  },

  list: {
    width: 250,
  },

  nested: {
    paddingLeft: "35px",
  },
}));

export default function NavBar() {
  const classes = useStyles();

  const [drawer, setDrawer] = React.useState(false);

  const openDrawer = () => {
    setDrawer(true);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const [toolBar, setToolBar] = React.useState([]);

  // get tools
  React.useEffect(() => {
    let toolLinks = {
      text: "Tools",
      type: "menu",
      menuItems: [],
    };

    const fields = ["id", "title", "short_title", "href", "parent_group"];


    ToolService.getTools(fields)
      .then(async (response) => {

        const fetchLinks = async () => {
          try {
            const navigationLinks = await getNavigationLinks();
            return navigationLinks;
          } catch (error) {
            console.error("Failed to fetch navigation links:", error);
            return [];
          }
        };

        const groupedTools = _.groupBy(response.results, (x) => {
          return x.parent_group;
        });
        for (const prop in groupedTools) {
          // reconstruct tools elements to be compatible with existing implementation
          const toolArray = groupedTools[prop];
          const items = toolArray.map((tool) => {
            return {
              text: tool.short_title ? tool.short_title : tool.title,
              linkType: tool.href.includes("http") ? "outer" : "inner",
              to: `/tools/${tool.href}`,
            };
          });
          if (prop === "null") {
            toolLinks.menuItems.push(...items);
          } else {
            toolLinks.menuItems.push({
              text: prop,
              type: "menu",
              menuItems: items,
            });
          }
        }

        const links = await fetchLinks();
        links.splice(1, 0, toolLinks);
        setToolBar(links);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  return (
    <header>
      <AppBar position="static">
        <Toolbar className={classes.toolBar} variant="dense">
          {/** logo */}
          <Link to={"/"} style={{ marginRight: "5vw" }}>
            <Logo />
          </Link>

          {/** navigation items */}
          <NavigationGrid toolBar={toolBar} />

          {StorageService.getUserInformation() !== null ? (
            <UserAccountMenu style={{ marginLeft: "auto" }} />
          ) : (
            <Button
              component={Link}
              to="/signin"
              style={{ marginLeft: "auto", color: "white" }}
            >
              Sign&nbsp;In
            </Button>
          )}
          <Hidden lgUp>
            <IconButton
              edge="end"
              display="none"
              className={classes.menuButton}
              onClick={openDrawer}
              color="inherit"
              aria-label="menu"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>

      {/* hide elements if the screen size is smaller than the breakpoint [lgUp] */}
      <Hidden lgUp>
        <Drawer anchor="top" open={drawer} onClose={closeDrawer}>
          <List component="nav">
            {toolBar.map((link) => {
              return (
                <NavbarListItem
                  key={link.text}
                  link={link}
                  closeDrawer={closeDrawer}
                />
              );
            })}
          </List>
        </Drawer>
      </Hidden>
    </header>
  );
}
