import React from "react";

import {Table, TableBody, TableCell,  TableHead, TableRow, Link} from "@mui/material"
import baseStyles from '../../css/const';

import information from '../../json/benchmark.json'

export default function BenchmarkIdExplainer(props) {
  const classes = baseStyles()

  const id = props.benchmarkId.split(":")

  // Get Vehicle Model explanation
  const model = information['vehicle-models'].find(
    element => element.id === id[0].slice(0,-1)
  );

  const vehicle_type = information['vehicle-types'].find(
      element => element.id === id[0].slice(-1)
  );

  // Get Cost Function data
  const costFunction = information['cost-functions'].find(
    element => element.id === id[1]
  );

  // Benchmark ID
  const benchmarkId = {id: id[2], name: "no name needed", link: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios"}

  // Get Version
  const version = information['versions'].find(
    element => element.id === id[3]
  );

  return (
    <React.Fragment>
      <Table key='outer-explain' className={classes.table} aria-label="simple table">
        <TableHead>
          <TableCell>
            {/* empty */}
          </TableCell>
          <TableCell>
            <b>Vehicle Model</b><br />
            <Link
              href="https://gitlab.lrz.de/tum-cps/commonroad-vehicle-models/-/blob/master/vehicleModels_commonRoad.pdf"
              underline="hover">
              See documentation
            </Link>
          </TableCell>
          <TableCell>
            <b>Vehicle Type</b><br />
          </TableCell>
          <TableCell>
            <b>Cost Function</b><br />
            <Link
              href="https://gitlab.lrz.de/tum-cps/commonroad-cost-functions/-/blob/master/costFunctions_commonRoad.pdf"
              underline="hover">
              See documentation
            </Link>
          </TableCell>
          <TableCell><b>Scenario ID</b></TableCell>
          <TableCell><b>Version</b></TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><b>ID</b></TableCell>
            <TableCell>{model.id}</TableCell>
            <TableCell>{vehicle_type.id}</TableCell>
            <TableCell>{costFunction.id}</TableCell>
            <TableCell>{benchmarkId.id}</TableCell>
            <TableCell>{version.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell><b>Explanation</b></TableCell>
            <TableCell>{model.description}</TableCell>
            <TableCell>{vehicle_type.description}</TableCell>
            <TableCell>
              <Table key='inner-explain' size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Partial Cost Functions</b></TableCell>
                    <TableCell><b>Weight</b></TableCell>
                  </TableRow>
                </TableHead>
              {
                costFunction.costs.map( n => {
                  return (
                    <TableRow key={n}>
                      <TableCell>{n.name}</TableCell>
                      <TableCell>{n.weight}</TableCell>
                    </TableRow>
                  )
                })
              }
              </Table>
            </TableCell>
            <TableCell>
              {/* currently empty*/}
            </TableCell>
            <TableCell>
              {/* currently empty*/}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
