import React from "react";

import { AppBar, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import TabPanel from "../../../components/tab-panel";
import LargeLayout from "../../../components/layouts/large-layout";
import useDocTitle from "../../../hooks/use-doc-title";
import useTracker from "../../../hooks/use-tracker";
import { Link } from "react-router-dom";

import baseStyles from "../../../css/const";
import QuickAccess from "../../../components/quick-access";
import AllSubmissions from "./tabs/all-submissions";
import ChallengeSubmissons from "./tabs/challenge-submissions";

export default function SubmissionsMain(props) {
  const classes = baseStyles();

  // check whether router props were set
  const tabName = props.location.state
    ? props.location.state.activeTab
    : "default";
  const activityId = props.location.state
    ? props.location.state.activityId
    : null;

  useDocTitle("My Submissions");
  useTracker();

  // appbar states
  const tabValue_ = tabName === "default" ? 0 : 1;
  const [tabValue, setTabValue] = React.useState(tabValue_);

  return (
    <LargeLayout>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={12} lg={10} xl={10}>
          <AppBar position="static" style={{ backgroundColor: "#ffffff" }}>
            <Tabs
              value={tabValue}
              onChange={(event, val) => setTabValue(val)}
              indicatorColor="primary"
              textColor="primary"
              backgroundColor="primary"
            >
              <Tab
                label="All Submissions"
                style={{ outline: "none", marginRight: "15px" }}
              />
              <Tab
                label="Challenge Submissions"
                style={{ outline: "none", marginRight: "15px" }}
              />
            </Tabs>
          </AppBar>

          <div style={{ marginTop: "5px" }} />

          {tabValue === 0 && (
            <TabPanel>
              <AllSubmissions />
            </TabPanel>
          )}

          {tabValue === 1 && (
            <TabPanel>
              <ChallengeSubmissons activityId={activityId} />
            </TabPanel>
          )}
        </Grid>

        <Grid item xs={12} lg={2} xl={2}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item>
              <QuickAccess />
            </Grid>
            <Grid item>
              <Paper className={classes.paper}>
                <Typography align="left">
                  Review the results of your submissions before publishing using
                  the function{" "}
                  <a
                    href={
                      "https://commonroad.in.tum.de/docs/commonroad-drivability-checker/sphinx/api_feasibility.html#commonroad_dc.feasibility.solution_checker.valid_solution"
                    }
                  >
                    valid_solution()
                  </a>{" "}
                  from the{" "}
                  <Link to={"/drivability-checker"}>Drivability Checker</Link>.
                  Submissions are checked for collisions, leaving the road, and
                  their kinematic feasibility with respect to the chosen vehicle
                  model.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LargeLayout>
  );
}
