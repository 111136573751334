import getInstance from "../instances";

export default class ActivityService {
  static API_URL = process.env.REACT_APP_API_URL + "activity";

  static async create(data) {
    const config = {
      withCredentials: true,
    };

    return getInstance("auth")
      .post(`${this.API_URL}/generate/`, data, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async update(id, data) {
    const config = {
      withCredentials: true,
    };

    return getInstance("auth")
      .patch(`${this.API_URL}/${id}/`, data, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async deleteActivity(id) {
    return getInstance("auth")
      .delete(`${this.API_URL}/${id}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static async getActivities(params = {}) {
    return getInstance("auth")
      .get(`${this.API_URL}/`, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getActivity(id = null) {
    return getInstance("auth")
      .get(`${this.API_URL}/${id}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getActivityDescription(id = null) {
    return getInstance("auth")
      .get(`${this.API_URL}/${id}/metadata/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async enrollActivity(id = null) {
    return getInstance("auth")
      .get(`${this.API_URL}/${id}/enroll/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static async getActivityRanking(id = null, params = null, docker = null) {
    const dockerOrNot = docker !== null && docker ? "/docker_submissions" : "";
    const url = `${this.API_URL}/${id}${dockerOrNot}/ranking/`;
    return getInstance("auth")
      .get(url, { params: params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  // to show the user's rank in challenges tab of my-submissions
  static async getFullActivityRanking(
    id,
    params = null,
    docker = null,
    data = []
  ) {
    const dockerOrNot = docker !== null && docker ? "/docker_submissions" : "";
    return getInstance("auth")
      .get(`${this.API_URL}/${id}${dockerOrNot}/ranking/`, { params: params })
      .then((res) => {
        data.push(...res.data.results);
        if (res.data.next === null) {
          return data;
        }
        const _params = { page: params.page + 1 };
        return this.getFullActivityRanking(id, _params, docker, data);
      });
  }

  static async getActivityUsers(id, params = null) {
    return getInstance("auth")
      .get(`${this.API_URL}/${id}/users/`, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getActivityUserBenchmarks(
    id,
    userId,
    params = null,
    docker = null
  ) {
    const partial =
      docker !== null && docker === true
        ? "docker_submissions"
        : `${userId}/solutions`;
    const url = `${this.API_URL}/${id}/users/${partial}/`;
    return getInstance("auth")
      .get(url, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getActivityDockerSubmissionErrors(id, userId) {
    return getInstance("auth")
      .get(`${this.API_URL}/docker_submissions/${id}/errors/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static async getActivityDockerSubmissionStats(id) {
    return getInstance("auth")
      .get(`${this.API_URL}/${id}/docker_submissions/stats/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
