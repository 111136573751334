import React from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import ChallengeLeaderboardRow from "../../../components/challenge/challenge-leaderboard/challenge-leaderboard-row";
import ActivityService from "../../../services/api/activity-service";
import StorageService from "../../../services/local-storage/storage-service";
import InfoIcon from "@mui/icons-material/Info";
import baseStyles from "../../../css/const";

export default function ChallengeLeaderboardTable(props) {
  const classes = baseStyles();
  const [participants, setParticipants] = React.useState([]);
  const isDocker = props.hidden;
  const [hidden, setHidden] = React.useState(props.hidden);

  // table states
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(true);

  // if user "is_staff" hidden is set to false so activity can be requested
  React.useEffect(() => {
    const user = StorageService.getUserInformation();
    if (user !== null && user.is_staff) {
      setHidden(false);
    }
  }, []);

  React.useEffect(() => {
    const params = {
      page: page + 1,
      page_size: rowsPerPage,
    };

    ActivityService.getActivityRanking(props.id, params, props.hidden)
      .then((response) => {
        setCount(response.data.count);
        setParticipants(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, rowsPerPage, props.id, props.hidden]);

  return (
    <React.Fragment>
      {isDocker && !hidden ? (
        <div>
          <Grid
            item
            component={Paper}
            variant={"outlined"}
            style={{ backgroundColor: "#f5f5f7", margin: "10px" }}
            className={classes.paper}
          >
            <InfoIcon /> This is an admin view of the leaderboard, log out to
            see the leaderboard for every user!
          </Grid>
        </div>
      ) : null}
      <TableContainer style={{ overflow: "hidden" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Rank</TableCell>
              {isDocker && !hidden ? <TableCell>Public</TableCell> : null}{" "}
              {/*only visible to admins*/}
              <TableCell>Username</TableCell>
              <TableCell>Institution</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Top 1 solutions</TableCell>
              <TableCell>Solved Scenarios</TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            style={{
              position: "relative",
            }}
          >
            {
              <Backdrop
                style={{ position: "absolute", zIndex: 1, opacity: 0.3 }}
                open={loading}
              >
                <CircularProgress />
              </Backdrop>
            }
            {participants.map((participant) => (
              <ChallengeLeaderboardRow
                key={participant.id}
                {...participant}
                activityId={props.id}
                hidden={hidden}
                isDocker={isDocker}
              />
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageChange={(event, page) => {
            setPage(page);
            setLoading(true);
          }}
          onRowsPerPageChange={(event) => {
            setPage(0);
            setRowsPerPage(event.target.value);
            setLoading(true);
          }}
        />
      </TableContainer>
    </React.Fragment>
  );
}
