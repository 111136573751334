import React from "react";
import { TableCell, TableSortLabel } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
export default function SortableTableHeader(props) {
  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "#000",
    },
  }))(TableSortLabel);
  return (
    <>
      {props.fields.map((field, index) => (
        <TableCell
          key={index}
          style={{ borderBottom: "none", paddingBottom: "0px" }}
          onClick={props.sortSelectedColumn}
        >
          <StyledTableSortLabel
            active={
              props.selectedColumn.col === field.id &&
              props.selectedColumn.sort !== "-"
            }
            direction={props.selectedColumn.sort}
            onClick={props.sortSelectedColumn}
          >
            {field.label}
          </StyledTableSortLabel>
        </TableCell>
      ))}
    </>
  );
}
