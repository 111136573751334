import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, Button, Checkbox } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import { Link } from "react-router-dom";
import baseStyles from "../../css/const";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& > *": {
      borderBottom: "unset",
    },
  },

  line: {
    borderBottom: "1px solid #ddd",
  },
}));

export default function ScenarioRow(props) {
  const baseClasses = baseStyles();
  const classes = useStyles();

  const [open, setOpen] = React.useState(props.expandAll);
  const [additionalFieldsSelected, setAdditionalFieldsSelected] =
    React.useState([]);

  const linkRef = React.createRef();

  React.useEffect(() => {
    setOpen(props.expandAll);
  }, [props.expandAll]);

  React.useEffect(() => {
    setAdditionalFieldsSelected(props.additionalFieldsSelected);
  }, [props.additionalFieldsSelected]);

  if (props.metadata) {
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell style={{ paddingRight: "0px", width: "40px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {props.selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                name={props.scenario_id}
                onChange={(e) => props.onSelect(e, props.version)}
                checked={props.selected}
              />
            </TableCell>
          )}
          <TableCell>
            <Typography
              variant="h7"
              style={{ fontFamily: "monospace", color: "rgba(0, 0, 0, 0.87)" }}
            >
              {props.scenario_id}
            </Typography>
          </TableCell>
          <Hidden mdDown>
            <TableCell>{props.version}</TableCell>
          </Hidden>
          <Hidden lgDown>
            <TableCell>{props.metadata.time_horizon.toFixed(2)}</TableCell>
          </Hidden>
          <Hidden lgDown>
            <TableCell>
              {new Date(props.created)
                .toLocaleDateString("en-GB")
                .replace(/\//g, ".")}
            </TableCell>
          </Hidden>
          {additionalFieldsSelected.map((field) => {
            if (field.id === "avg_solution_success_rate") {
              return (
                <Hidden lgDown>
                  <TableCell>{props[field.id].toFixed(2)}</TableCell>
                </Hidden>
              );
            }
            return (
              <Hidden lgDown>
                <TableCell>{props[field.id]}</TableCell>
              </Hidden>
            );
          })}
        </TableRow>

        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6 + additionalFieldsSelected.length}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
              </Box>
              <Grid
                container
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item sm={12} md={6}>
                  <img
                    src={props.gif}
                    alt="scenario video"
                    style={{
                      width: "auto",
                      height: "auto",
                      margin: "auto",
                      maxHeight: "300px",
                      maxWidth: "100%",
                      display: "block",
                    }}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12}>
                      <Link
                        to={`/scenarios/${props.scenario_id}/benchmarks`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: "10px" }}
                      >
                        <Button className={baseClasses.button} fullWidth={true}>
                          Benchmark Rankings
                        </Button>
                      </Link>
                      <a ref={linkRef} style={{ display: "none" }}>
                        Download URL
                      </a>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginTop: "25px",
                        marginBottom: "25px",
                      }}
                    >
                      <tr className={classes.line}>
                        <th className={classes.line}>Scenario&nbsp;Version</th>
                        <td className={classes.line}>{props.version}</td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Data&nbsp;Source</th>
                        <td className={classes.line}>
                          {props.metadata.sources.join(", ")}
                        </td>
                      </tr>
                      <tr>
                        <th className={classes.line}>
                          Obstacle&nbsp;Behavior&nbsp;Type
                        </th>
                        <td className={classes.line}>
                          {props.metadata.behavior_type}
                        </td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Scenario&nbsp;Tags</th>
                        <td className={classes.line}>
                          {props.metadata.tags.join(", ")}
                        </td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Obstacle&nbsp;Classes</th>
                        <td className={classes.line}>
                          {props.metadata.obstacle_types.join(", ")}
                        </td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Goal&nbsp;Types</th>
                        <td className={classes.line}>
                          {props.metadata.goal_types.join(", ")}
                        </td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Scenario&nbsp;Duration</th>
                        <td className={classes.line}>
                          {props.metadata.time_horizon.toFixed(2)} s
                        </td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>Initial&nbsp;Velocity</th>
                        <td className={classes.line}>
                          {props.metadata.max_initial_velocity.toFixed(2)} m/s
                        </td>
                      </tr>
                      <tr>
                        <th className={classes.line}>
                          No.&nbsp;of&nbsp;Static&nbsp;Obstacles
                        </th>
                        <td className={classes.line}>
                          {props.metadata.static_obstacle_count}
                        </td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>
                          No.&nbsp;of&nbsp;Dynamic&nbsp;Obstacles
                        </th>
                        <td className={classes.line}>
                          {props.metadata.dynamic_obstacle_count}
                        </td>
                      </tr>
                      <tr className={classes.line}>
                        <th className={classes.line}>
                          No.&nbsp;of&nbsp;Ego&nbsp;Vehicles
                        </th>
                        <td className={classes.line}>
                          {props.metadata.ego_vehicle_count}
                        </td>
                      </tr>
                      {/* <tr className={classes.line}>  # we will add new custom component for Common-crime params later
                        <th className={classes.line}>Time-To-Collision</th>
                        <td className={classes.line}>
                          {props.metadata.crime_ttc.toFixed(2)} s
                        </td>
                      </tr> */}
                    </table>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>{props.scenario_id}</TableCell>
          <Hidden mdDown>
            <TableCell>{props.version}</TableCell>
          </Hidden>
          {additionalFieldsSelected.map((field) => {
            return (
              <Hidden lgDown>
                <TableCell>{field}</TableCell>
              </Hidden>
            );
          })}
          <Hidden lgDown>
            <TableCell></TableCell>
          </Hidden>
        </TableRow>
      </React.Fragment>
    );
  }
}
