import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/default-layout';


export default function RuntimeError() {

  return (
    <DefaultLayout>
      <Typography variant='body1' align='center'>
        Looks like something has crashed. We have notified the support team about the issue.
      </Typography>

      <Typography variant="body1" align="center">
        Click <Link to="/">here</Link> to go to the main page. 
      </Typography>
    </DefaultLayout>
  )
}