import getInstance from "../instances";

export default class ExternalProjectsService {
    static API_URL = process.env.REACT_APP_API_URL + "externalprojects/";

    static async getExternalProjects(params = {}) {
        return getInstance("auth")
            .get(`${this.API_URL}`, {
                params: params,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
}