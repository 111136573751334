import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  ListItemIcon,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 275,
    maxWidth: 275,
    marginBottom: "10px",
  },
  label: {
    fontSize: "14px",
  },
  dropDownItem: {
    fontSize: "14px",
  },
  listItemText: {
    fontSize: "0.7em",
  },
  menu: {
    display: "flex",
  },
  selectField: {
    fontSize: "14px",
  },
  selectAllItem: {
    minWidth: "42px",
  },
  selectAllText: {
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  indeterminateColor: {
    color: "#f50057",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  fontSize: "0.875rem",
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 275,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const TextProps = {
  style: { fontSize: "0.875rem" },
};

const options = [
  "Number of Obstacles",
  "Number of Solutions",
  "Average Solution Success Rate",
];

export default function ScenarioTableDropdown(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      props.onSelectedFields(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(event.target.value);
    props.onSelectedFields(event.target.value);
  };

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel className={classes.label}>Additional Fields</InputLabel>
      <Select
        variant="standard"
        className={classes.selectField}
        labelId="demo-mutiple-checkbox-label"
        multiple
        value={selected}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => {
          // show selected fields inside select comp
          let map = {
            "Number of Obstacles": "#Obstacles",
            "Number of Solutions": "#Solutions",
            "Average Solution Success Rate": "%Avg Success",
          };
          let out = [];
          selected.forEach((sel) => {
            out.push(map[sel]);
          });
          return out.join(", ");
        }}
        // renderValue={() => ""}
        MenuProps={MenuProps}>
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon className={classes.selectAllItem}>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selected.indexOf(option) > -1} />
            <ListItemText primary={option} primaryTypographyProps={TextProps} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
