import React from "react";
import Dataset from "./dataset";
import DefaultLayout from "../../components/layouts/default-layout";
import StorageService from "../../services/local-storage/storage-service";
import { List, Typography } from "@mui/material";
import DatasetService from "../../services/api/dataset-service";
import { useEffect, useState } from "react";

export default function Datasets() {
  const user_id = StorageService.getUserId();
  const disabled = !Boolean(user_id);
  // if we need multiple datasets, implement upload/get dataset api (do not add const value here!)
  const [datasets, setDatasets] = useState([]);
  useEffect(() => {
    DatasetService.getDatasets()
      .then((response) => {
        setDatasets(response.data);
      })
      .catch((error) => {
        // do nothing
      });
  }, []);

  return (
    <DefaultLayout>
      <Typography variant="h4" align="center">
        Datasets
      </Typography>
      {disabled ? (
        <Typography variant="body1" align="center">
          To download the datasets, please sign in.
        </Typography>
      ) : (
        <></>
      )}
      <div>
        <List>
          {datasets.map((dataset) => (
            <Dataset dataset={dataset} disabled={disabled} />
          ))}
        </List>
      </div>
    </DefaultLayout>
  );
}
