import React from "react";
import DefaultLayout from "../../components/layouts/default-layout";
import ScenariosContainer from "./scenarios-container";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import StorageService from "../../services/local-storage/storage-service";
import Typography from "@mui/material/Typography";
import baseStyles from "../../css/const";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";

export default function Scenario() {
  const [selected, setSelected] = React.useState();
  const user = StorageService.getUserInformation();

  useDocTitle("Scenarios");
  useTracker();

  const classes = baseStyles();

  return (
    <DefaultLayout>
      <Grid container direction={"column"} spacing={1}>
        <Grid item>
          <Typography className={classes.title}>
            Scenario visualization and selection
          </Typography>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <Typography className={classes.typoContent} variant="body1">
              The traffic scenarios range from simple scenarios with a few
              obstacles to complex intersection scenarios with many road users.
              The scenarios are either obtained from real traffic data (e.g.,
              NGSIM dataset), simulations, or are hand-crafted. The data for
              each scenario is stored in separate XML files that can be
              downloaded from below or from the{" "}
              <a href={"https://gitlab.lrz.de/tum-cps/commonroad-scenarios"}>
                scenario repository
              </a>
              . For details on the format of the scenario files, please download
              the{" "}
              <a
                href={
                  "https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_2020a.pdf"
                }
              >
                XML format documentation
              </a>
              .
            </Typography>
          </Paper>
        </Grid>
        <Grid item>
          <ScenariosContainer
            selectable={true}
            downloadable={true}
            selected={selected}
            setSelected={setSelected}
            showPublicFilter={user !== null ? user.is_staff : false}
          />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
