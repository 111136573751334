import getInstance from "../instances";

export default class CompetitionsService {
    static API_URL = process.env.REACT_APP_API_URL + "competitions/";

    static async getCompetitions(params = {}) {
        return getInstance("auth")
            .get(`${this.API_URL}`, {
                params: params,
            })
            .then((response) => response)
            .catch((error) => error.response);
    }

    static async getCompetitionFiles(year = null, fileType = null) {
        let url = `${this.API_URL}files/`;
        let params = {};

        if (year) params.year = year;
        if (fileType) params.file = fileType;

        return getInstance("auth")
            .get(url, { params })
            .then((response) => { return response; })
            .catch((error) => error.response);
    }

    static async getLatestCompetition(fileType = null) {
        let url = `${this.API_URL}latest/`;
        let params = {};

        if (fileType) params.file = fileType;

        return getInstance("auth")
            .get(url, { params })
            .then((response) => response)
            .catch((error) => error.response);
    }

    static async getInfo(year = null, latest = false) {
        let url = `${this.API_URL}info/`;
        let params = {};

        if (latest) params.latest = latest;
        if (!latest && year) params.year = year;

        return getInstance("auth")
            .get(url, { params })
            .then((response) => response)
            .catch((error) => error.response);
    }
}
