import React from "react";

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import DoneIcon from "@mui/icons-material/Done";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import withStyles from '@mui/styles/withStyles';

import { Link } from "react-router-dom";
import LargeLayout from "../../components/layouts/large-layout";
import ActivityService from "../../services/api/activity-service";
import baseStyles from "../../css/const";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import QuickAccess from "../../components/quick-access";
import SortableTableHeader from "../../components/sort/table-header";

import StorageService from "../../services/local-storage/storage-service";

const LabelButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
}))(Button);

export default function Challenges() {
  const classes = baseStyles();

  useDocTitle("Challenges");
  useTracker();

  const [activities, setActivities] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedColumn, setSelectedColumn] = React.useState({
    col: "title",
    sort: "-",
  });
  const [sort, setSort] = React.useState(true);

  const sortSelectedColumn = (e) => {
    const columnDbMap = {
      Challenges: "title",
      Participants: "participants",
      Enrollment: "enrolled",
      "Submission type": "hidden",
      Status: "status",
    };

    let selectedField = columnDbMap[e.currentTarget.textContent];
    let sortState = "-";

    if (selectedColumn.col !== selectedField && selectedColumn.col !== "") {
      setSelectedColumn((current) => {
        return { ...current, col: selectedField, sort: "desc" };
      });
      return;
    }

    if (selectedColumn.sort === "-") sortState = "desc";
    if (selectedColumn.sort === "desc") sortState = "asc";
    if (selectedColumn.sort === "asc") sortState = "-";

    setSelectedColumn((current) => {
      return { ...current, col: selectedField, sort: sortState };
    });
  };

  const headCells = [
    { id: "title", label: "Challenges", hidden: null },
    { id: "participants", label: "Participants", hidden: "xsDown" },
    { id: "enrolled", label: "Enrollment", hidden: "xsDown" },
    { id: "hidden", label: "Submission type", hidden: "xsDown" },
    { id: "status", label: "Status", hidden: "xsDown" },
  ];

  React.useEffect(() => {
    if (sort) {
      let sortParams = getSortParams();
      let params = {};
      if (
        sortParams.ordering !== undefined &&
        ["title", "participants"].some((field) =>
          sortParams.ordering.includes(field)
        )
      ) {
        params = sortParams;
      }
      ActivityService.getActivities(params)
        .then((response) => {
          const user = StorageService.getUserInformation();

          let tempActivities = [];
          for (const activity of response.data.results) {
            const today = Date.now();
            const start_date = new Date(activity.start_date);
            const end_date = new Date(activity.end_date);
            const status =
              today < start_date
                ? "Incoming"
                : today <= end_date
                ? "Active"
                : "Completed";

            const tempActivity = {
              id: activity.id,
              title: activity.title,
              summary: activity.summary,
              enrolled:
                user === null
                  ? -1
                  : user.activities.includes(activity.id)
                  ? 1
                  : 0,
              hidden: activity.hidden,
              participants: activity.user_count,
              status: status,
            };

            tempActivities.push(tempActivity);
          }

          return tempActivities;
        })
        .then((res) => {
          sortInFront(res, sortParams);
          setActivities(res);
          setSort(false);
          setLoading(false);
        });
    }
  }, [sort]);

  const sortInFront = (res, sortParams) => {
    if (
      sortParams.ordering &&
      !["title", "participants"].some((field) =>
        sortParams.ordering.includes(field)
      )
    ) {
      if (sortParams.ordering && sortParams.ordering.includes("-")) {
        let field = sortParams.ordering.substring(1);
        res.sort((a, b) => {
          return a[field] < b[field] ? -1 : 1;
        });
      } else if (sortParams.ordering) {
        let field = sortParams.ordering;
        res.sort((a, b) => {
          return a[field] < b[field] ? 1 : -1;
        });
      }
    }
    return res;
  };

  React.useEffect(() => {
    handleSortedScenarios(selectedColumn);
  }, [selectedColumn]);

  const handleSortedScenarios = (selectedColumn) => {
    setSelectedColumn(selectedColumn);
    setSort(!sort);
    setLoading(true);
  };

  const getSortParams = () => {
    if (selectedColumn != null) {
      let sortDirection = selectedColumn.sort;
      let sortQuery = ""; // by defult "asc"

      if (sortDirection !== "-") {
        if (sortDirection === "desc") sortQuery = "-"; // convert "desc" into "-"
        return { ordering: sortQuery + selectedColumn.col };
      }
      return {};
    } else {
      return {};
    }
  };

  return (
    <LargeLayout>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        spacing={3}
      >
        <Grid item>
          <Typography className={classes.title} align="left" gutterBottom>
            Challenges
          </Typography>
          <Typography align="left" gutterBottom>
            Compete against motion planners of other users in predefined
            benchmark sets. Host your own challenge as described in this{" "}
            <a href={"/challenges/tutorial"}>tutorial</a>.
          </Typography>
        </Grid>

        <Grid item container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={12} lg={10} xl={10}>
            <Paper className={classes.paper}>
              <TableContainer container={Paper}>
                <Table>
                  <TableHead>
                    <SortableTableHeader
                      fields={headCells}
                      selectedColumn={selectedColumn}
                      sortSelectedColumn={sortSelectedColumn}
                    />
                  </TableHead>

                  <TableBody>
                    {loading && (
                      <TableRow style={{ height: "60px" }}>
                        <TableCell colSpan={5}>
                          <Grid container justifyContent="center">
                            <Grid item>
                              <CircularProgress />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                    {!loading &&
                      activities.map((activity) => (
                        <TableRow
                          component={Link}
                          to={`/challenges/id/${activity.id}`}
                          key={activity.id}
                          style={{ textDecoration: "none" }}
                        >
                          <TableCell>
                            <Grid container spacing={3}>
                              <Grid item xs sm container>
                                <Grid
                                  item
                                  xs
                                  container
                                  direction="column"
                                  justifyContent="flex-end"
                                >
                                  <Grid item xs>
                                    <Typography variant="body2" gutterBottom>
                                      {activity.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      {activity.summary}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>

                          <TableCell>{activity.participants}</TableCell>
                          <TableCell>
                            {activity.enrolled === -1 ? (
                              <Tooltip
                                title={
                                  "You have to be logged-in in order to see your registration status"
                                }
                              >
                                <RemoveCircleOutlineIcon htmlColor="" gray />
                              </Tooltip>
                            ) : activity.enrolled === 1 ? (
                              <DoneIcon htmlColor="green" />
                            ) : (
                              <BlockIcon htmlColor="red" />
                            )}
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={2}>
                              <Grid item>
                                <LabelButton
                                  variant="contained"
                                  size="small"
                                  disableFocusRipple
                                >
                                  {activity.hidden === true
                                    ? "Docker Image URL"
                                    : "XML files"}
                                </LabelButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell>{activity.status}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={2} xl={2}>
            <QuickAccess />
          </Grid>
        </Grid>
      </Grid>
    </LargeLayout>
  );
}
