import React from "react";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { useState, useEffect, useCallback, useMemo } from "react";
import ExternalProjectsService from "../../services/api/externalprojects-service";
import { Table, TableHead, TableBody, TableRow, TableCell, Link, Typography, Box } from "@mui/material";
import { GitHub, Article } from '@mui/icons-material';

export default function ExternalProjectsPage() {
  useDocTitle("External Projects");
  useTracker();

  const [externalProjects, setExternalProjects] = useState([]);

  useEffect(() => {
    ExternalProjectsService.getExternalProjects()
      .then((response) => {
        setExternalProjects(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <DefaultLayout>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        External Projects
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell>Author/Institution</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Publication</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {externalProjects.map((externalProject) => (
            <TableRow key={externalProject.id}>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  {externalProject.project}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{externalProject.author_institution}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{externalProject.description}</Typography>
              </TableCell>
              <TableCell>
                <Link href={externalProject.code} target="_blank" rel="noreferrer">
                  <GitHub />
                </Link>
              </TableCell>
              <TableCell>
                <Link href={externalProject.publication} target="_blank" rel="noreferrer">
                  <Article />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DefaultLayout>
  );
}