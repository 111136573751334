import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Link, Paper, CardMedia } from "@mui/material";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
    margin: "auto",
    transition: "0.15s",
    // boxShadow: "2px 5px 30px -8px rgba(0,0,0,0.2)",
    "&:hover": {
      boxShadow: "0 16px 30px -5px rgba(0,0,0,0.2)",
    },
    height: "100%",
    media: {
      height: "50%",
      width: "50%",
    },
  },
  content: {
    top: "10px",
    variant: "outlined",
  },
}));


export default function InfoCard(props) {
  const { title, link, description, bg_image } = props;
  const classes = useStyles();

  return (
    <Link href={link} underline="hover">
      <Paper className={classes.paper} variant={"outlined"}>
        <CardActionArea>
          {bg_image && (
            <CardMedia
              src={bg_image.startsWith("http") ? bg_image : require("../../assets/" + bg_image).default}
              component="img"
              style={{ height: "130px", width: "100%", objectFit: "scale-down" }}
            />
          )}
          <CardContent>
            <Typography gutterBottom className={classes.subtitle} variant="h6">
              {title}
            </Typography>
            {description && <Typography className={classes.content}>{description}</Typography>}
          </CardContent>
        </CardActionArea>
      </Paper>
    </Link>
  );
}
