import React from "react";
import { Grid } from "@mui/material";
import NavBarItem from "./navbar-item";

const NavigationGrid = React.memo(
  ({ toolBar }) => {
    return (
      <Grid
        container
        spacing={1}
        alignItems="flex-start"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        {toolBar.map((link) => (
          <Grid key={link.text} item>
            <NavBarItem item={link} />
          </Grid>
        ))}
      </Grid>
    );
  },
  (prevProps, nextProps) => prevProps.toolBar === nextProps.toolBar
);

export default NavigationGrid;
