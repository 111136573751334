import {
  TableCell,
  TableRow,
  IconButton,
  Hidden,
  Collapse,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function SolutionRankingsTableRow(props) {
  const ranking = props.ranking;
  const expandAll = props.expandAll;
  const [open, setOpen] = React.useState(expandAll);

  React.useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  return (
    <React.Fragment>
      <TableRow
        tabIndex={-1}
        key={ranking.benchmark_id}
        style={{ textDecoration: "none" }}
      >
        <TableCell style={{ paddingRight: "0px", width: "40px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/solutions/ranking/${ranking.benchmark_id}/`}
            style={{ fontFamily: "monospace", color: "rgba(0, 0, 0, 0.87)" }}
          >
            {ranking.benchmark_id}
          </a>
        </TableCell>
        <TableCell>{ranking.user.username}</TableCell>
        <Hidden lgDown>
          <TableCell>{ranking.user.profile.country}</TableCell>
          <TableCell>{ranking.user.profile.institution}</TableCell>
        </Hidden>
        <TableCell>{ranking.solution_count}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider></Divider>
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Grid xs="auto" item>
                {props.ranking.gif ? (
                  <div>
                    <Typography
                      variant="h7"
                      gutterBottom
                      component="div"
                      style={{
                        fontSize: "small",
                        color: "gray",
                      }}
                    >
                      Best Solution:
                    </Typography>
                    <img
                      key={props.ranking.gif}
                      alt=""
                      src={props.ranking.gif}
                    />
                  </div>
                ) : (
                  <Typography variant="h6" gutterBottom component="div">
                    No visualization
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
