import React from "react";

import { Paper } from "@mui/material";
import DefaultLayout from "../../../components/layouts/default-layout";
import useDocTitle from "../../../hooks/use-doc-title";
import useTracker from "../../../hooks/use-tracker";
import Grid from "@mui/material/Grid";
import { QuickAccessCompetition } from "../../../components/quick-access-competition";
import baseStyle from "../../../css/const";

import CompetitionMarkdown from "./competition-markdown";
import Sponsor2021 from "./sponsors/2021";
import Sponsor2022 from "./sponsors/2022";
import Sponsor2023 from "./sponsors/2023";
import Sponsor2024 from "./sponsors/2024";

export default function CompetitionAnnouncement({ year = null, documentType = null }) {

    useDocTitle(`Competition ${year}`);
    useTracker();

    const classes = baseStyle();

    return (
        <DefaultLayout>
            <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} md={10}>
                    <Paper className={classes.paper}>
                        <CompetitionMarkdown year={year} documentType={documentType} />
                        {year === "2021" && <Sponsor2021 />}
                        {year === "2022" && <Sponsor2022 />}
                        {year === "2023" && <Sponsor2023 />}
                        {year === "2024" && <Sponsor2024 />}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={2}>
                    <QuickAccessCompetition year={year} />
                </Grid>
            </Grid>
        </DefaultLayout>
    );
}
