import React from "react";

export default function Logo() {
  return (
    <React.Fragment>
      <div style={{ display: "inline-block", width: "170px", height: "30px" }}>
        <img
          src={require("../assets/static_img_tum-logo-white-xs.svg").default}
          alt="logo"
          style={{ maxWidth: "100%", maxHeight: "100%", marginRight: "5px" }}
        />

        <img
          src={
            require("../assets/static_img_commonroad_logo_white.svg").default
          }
          alt="logo"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </div>
    </React.Fragment>
  );
}
