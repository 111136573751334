import getInstance from "../instances";
import StorageService from "../local-storage/storage-service";

export default class ScenarioService {
  static API_URL = process.env.REACT_APP_API_URL + "scenario/";

  static async getScenarios(params = {}) {
    const instanceType = getInstanceType();
    return getInstance(instanceType)
      .get(this.API_URL, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  static async getScenariosSummary(params = {}) {
    const instanceType = getInstanceType();

    return getInstance(instanceType)
      .get(`${this.API_URL}metadata/summary/`, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  static async getAllScenarioIds(params = {}) {
    const instanceType = getInstanceType();

    return getInstance(instanceType)
      .get(`${this.API_URL}scenario_ids/`, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getScenarioCount() {
    const instanceType = getInstanceType();

    return getInstance(instanceType)
      .get(`${this.API_URL}get_scenario_count/`, {})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}

const getInstanceType = () => {
  // Check if function is called by an admin. If so use the axiosAuthInstance
  const user = StorageService.getUserInformation();

  if (user !== null && user.is_staff) {
    return "admin";
  } else {
    return "default";
  }
};
