import React from "react";

import { Typography } from "@mui/material";

export default function Sponsor2021() {
    return (
        <>
            <Typography variant="h4">
                <b>Prize Sponsor</b>
                <br />
                <br />
                <img
                    style={{ width: "200px" }}
                    src={
                        require("../../../../assets/images/competition-announcement/2021/IVEX_logo.png")
                            .default
                    }
                    alt="tutorial preview"
                />
                <br />
                <br />
                <b>Acknowledgment</b>
                <br />
                <br />
                <img
                    style={{ width: "200px" }}
                    src={
                        require("../../../../assets/images/competition-announcement/2021/dfg_logo_englisch_blau_en.jpg")
                            .default
                    }
                    alt="tutorial preview"
                />
            </Typography>
            <Typography variant="body2">
                {" "}
                For a PDF version of this call, see here: &nbsp;
                <a
                    href={
                        require("../../../../assets/pdf/competition-announcement/2021/callForSubmissions_CR21.pdf")
                            .default
                    }
                    target="_blank"
                >
                    download.{" "}
                </a>
            </Typography>
        </>
    );
}
