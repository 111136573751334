import React from "react";
import DefaultLayout from "../../components/layouts/default-layout";
import { Typography } from "@mui/material";

export default function MaintenancePage() {
  return (
    <DefaultLayout>
      <Typography variant="h2" align="center">
        We&rsquo;ll be back soon!
      </Typography>
      <Typography variant="body1" align="center">
        Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.
      </Typography>
      <Typography variant="body1" align="center">
        &mdash; The CommonRoad Team
      </Typography>
    </DefaultLayout>
  );
}
