import React from "react";
import ReactMarkdown from "react-markdown";

import imprint from "../../assets/markdowns/imprint.md";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";

export default function Imprint() {
  useDocTitle("Impressum");
  useTracker();

  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    fetch(imprint)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <DefaultLayout>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <ReactMarkdown source={markdown}></ReactMarkdown>
      </div>
    </DefaultLayout>
  );
}
