import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ResearchCard from "../../components/cards/research-card";

import tools from "../../json/tools.json";
import papers from "../../json/papers.json";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";

export default function Publications() {
  useDocTitle("Publications");
  useTracker();

  return (
    <DefaultLayout>
      <main>
        <Grid container alignItems="stretch" justifyContent="left" spacing={3}>
          <Grid item>
            <Typography variant="h4"> Publications </Typography>
          </Grid>
          {Object.keys(tools)
            .map(function (item) {
              return tools[item].papers;
            })
            .flat()
            .map(function (item) {
              const cardProps = {
                title: item.title,
                authors: item.authors,
                BibTex: item.BibTex,
                more: item.more,
                fullText: item.fullText,
              };

              return (
                <Grid
                  item
                  sm={12}
                  md={0}
                  alignContent={"flex-start"}
                  alignItems={"flex-start"}
                >
                  <ResearchCard {...cardProps} />
                </Grid>
              );
            })}
          {papers.map(function (item) {
            const cardProps = {
              title: item.title,
              authors: item.authors,
              BibTex: item.BibTex,
              more: item.more,
              fullText: item.fullText,
            };

            return (
              <Grid
                item
                sm={12}
                md={0}
                alignContent={"flex-start"}
                alignItems={"flex-start"}
              >
                <ResearchCard {...cardProps} />
              </Grid>
            );
          })}
        </Grid>
      </main>
    </DefaultLayout>
  );
}
