import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const useIntersectionObserver = (setActiveId) => {
  const headersRef = React.useRef({});
  React.useEffect(() => {
    // callback func for observer
    const callback = (headers) => {
      headersRef.current = headers.reduce((map, header) => {
        map[header.target.id] = header;
        return map;
      }, headersRef.current);

      // visible headers
      let visibleHeaders = [];
      Object.keys(headersRef.current).forEach((key) => {
        const header = headersRef.current[key];
        if (header.isIntersecting) {
          visibleHeaders.push(header);
        }
      });

      // find header from its id
      const getIdxFromId = (id) => {
        return headers.findIndex((header) => header.id === id);
      };

      // select the visible element
      // if there are multiple visible elements, select the one closest to the top
      if (visibleHeaders.length === 1) {
        setActiveId(visibleHeaders[0].target.id);
      } else if (visibleHeaders.length > 1) {
        const sortedVisibleHeaders = visibleHeaders.sort(
          (x, y) => getIdxFromId(x.target.id) > getIdxFromId(y.target.id)
        );
        setActiveId(sortedVisibleHeaders[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "-40% 0px -40% 0px",
      // threshold: 0.5
    });

    // find headers
    const headers = Array.from(document.querySelectorAll(".toc-anchor"));

    headers.forEach((header) => observer.observe(header));

    return () => observer.disconnect();
  }, [setActiveId]);
};

export default function TOC({ headings }) {
  const handleOnClick = (event, id) => {
    event.preventDefault();
    document.querySelector(`#${id}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  const useStyles = makeStyles((theme) => ({
    link: {
      fontSize: "0.9em",
      color: "black",
      textDecoration: "none !important",
    },
    list: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      "& li": {
        "&.active": {
          backgroundColor: "white",
          "&::before": {
            opacity: 1,
            color: "blue",
          },
        },
        "&:hover": {
          backgroundColor: "white",
          "&::before": {
            color: "blue",
            opacity: 1,
          },
        },
        paddingLeft: "1rem",
        textIndent: "-0.75rem",
        "&::before": {
          content: '"| "',
          opacity: 0,
        },
      },
    },
  }));

  const classes = useStyles();

  const [activeId, setActiveId] = React.useState();
  useIntersectionObserver(setActiveId);

  return (
    <nav aria-label="Table of Contents" style={{ position: "sticky", top: "24px", paddingLeft: "20px" }}>
      <span>Contents</span>
      <ul className={classes.list}>
        {headings.map((heading) => {
          return (
            <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
              <a className={classes.link} onClick={(e) => handleOnClick(e, heading.id)} href={`#${heading.id}`}>
                {heading.text}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
