import getInstance from "../instances";

export default class NewsService {
    static API_URL = process.env.REACT_APP_API_URL + "news/";

    static async getNews(params = {}) {
    return getInstance("auth")
      .get(`${this.API_URL}`, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    }
}