import { InputAdornment, TextField } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React from "react";

export default function TableSearchField(props) {
  const [value, setValue] = React.useState("");
  const prevValueRef = React.useRef();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.reference.current = value;
      if (value !== null && value !== "") {
        props.setSearch(true);
      }
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (prevValueRef.current && !newValue) {
      props.reference.current = newValue;
      props.setSearch(true);
    }
  };

  React.useEffect(() => {
    prevValueRef.current = value;

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [value]);

  return (
    <React.Fragment>
      <TextField
        variant="standard"
        id="standard"
        value={value}
        onChange={handleChange}
        placeholder="Press Enter to search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterListIcon />
            </InputAdornment>
          ),
        }}
      />
    </React.Fragment>
  );
}
