import React from "react";

export default function Sponsor2024() {
    return (
        <>
            <h3>Acknowledgement</h3>
            <br />
            <img
                style={{ width: "200px" }}
                src={
                    require("../../../../assets/images/competition-announcement/2021/dfg_logo_englisch_blau_en.jpg")
                        .default
                }
                alt="tutorial preview"
            />
        </>
    );
}
