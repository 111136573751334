import getInstance from "../instances";

export default class SolutionService {
  static API_URL = process.env.REACT_APP_API_URL + "solution/";

  static async getBenchmarkNames(scenarioId = null, forRanking = false) {
    return getInstance()
      .get(`${this.API_URL}benchmarks/`, {
        params: {
          benchmark_id__contains: scenarioId,
          for_ranking: forRanking,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getBenchmarkRanking(params = {}) {
    const suffix = params.benchmarkId === null ? "global_ranking/" : `ranking/${params.benchmarkId}/`;

    return getInstance()
      .get(`${this.API_URL}${suffix}`, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  static async getSolutions(query = {}, all = false, data = []) {
    let instance = null;
    if (query.user__id) {
      // user solutions
      instance = getInstance("auth");
    } else {
      instance = getInstance();
    }
    return instance
      .get(`${this.API_URL}`, {
        params: query,
      })
      .then((response) => {
        // return all submissions of a user
        if (all) {
          data.push(...response.data.results);
          if (response.data.next === null) {
            return {
              data: {
                results: data,
              },
            };
          }
          query.page = query.page + 1;
          return this.getSolutions(query, all, data);
        }

        // return only the first page
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async totalSolutionCount() {
    return getInstance()
      .get(`${this.API_URL}count/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getDockerSubmissionSolutions(dockerSubmissionId, params = null) {
    const url = `${this.API_URL}admin/docker_submission/${dockerSubmissionId}/solutions/`;
    return getInstance("auth")
      .get(url, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getSolution(id = null) {
    return getInstance()
      .get(`${this.API_URL}${id}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getSolutionStatusCount(activity_id=null) {
    const params = {
      activity_id: activity_id
    }
    return getInstance("auth")
      .get(`${this.API_URL}status/count/`, {params: params})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        Promise.reject(error.response);
      });
  }

  static async getSolutionMetadata(params = {}) {
    return getInstance()
      .get(`${this.API_URL}metadata/`, {
        params: params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  static async uploadSolution(formData) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: true,
    };

    return getInstance("auth")
      .post(`${this.API_URL}`, formData, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async updateSolution(
    id = null,
    method = null,
    algorithm = null,
    publication = null,
    code_url = null,
    computation_time = null
  ) {
    const data = {
      method: method,
      algorithm: algorithm,
      publication: publication,
      code_url: code_url,
      computation_time: computation_time,
    };
    // send non-null/empty data only
    Object.keys(data).forEach((k) => (data[k] === null || !data[k]) && delete data[k]);

    return getInstance("auth")
      .patch(`${this.API_URL}${id}/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async publishSolution(id = null) {
    return getInstance("auth")
      .patch(`${this.API_URL}${id}/`, { public: true })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async unPublishSolution(id = null) {
    return getInstance("auth")
      .patch(`${this.API_URL}${id}/`, { public: false })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async deleteSolution(id = null) {
    return getInstance("auth")
      .delete(`${this.API_URL}${id}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getDockerSolutions(params = {}) {
    return getInstance("auth")
      .get(`${this.API_URL}docker_submission/`, { params: params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getDockerSolution(id, params = {}) {
    return getInstance("auth")
      .get(`${this.API_URL}docker_submission/${id}/`, { params: params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async getDockerSolutionStatusCount(activity_id) {
    const params = {
      activity_id: activity_id
    }
    return getInstance("auth")
      .get(`${this.API_URL}docker_submission/status/count/`, {params: params})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        Promise.reject(error.response);
      });
  }

  static async uploadDockerSolution(data = {}) {
    return getInstance("auth")
      .post(`${this.API_URL}docker_submission/`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async updateDockerSolution(id, body = {}) {
    return getInstance("auth")
      .patch(`${this.API_URL}docker_submission/${id}/`, body)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async publishDockerSolution(id) {
    return getInstance("auth")
      .patch(`${this.API_URL}docker_submission/${id}/`, { public: true })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async unPublishDockerSolution(id) {
    return getInstance("auth")
      .patch(`${this.API_URL}docker_submission/${id}/`, { public: false })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async deleteDockerSolution(id) {
    return getInstance("auth")
      .delete(`${this.API_URL}docker_submission/${id}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error.response);
      });
  }

  static async getSolutionWaitingTasksCount() {
    return getInstance()
      .get(`${this.API_URL}waiting_tasks_count`, {})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}
