import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  IconButton,
  Button,
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Add as AddIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Save } from "@material-ui/icons";

const KeyValuePairsGrid = ({
  title = "Pair",
  pairs: initialPairs,
  setPairs,
  field1Title = "Field 1",
  field2Title = "Field 2",
  keyTitle = "key",
  valueTitle = "value",
  file,
  setFile,
  suggestions,
}) => {
  const [localPairs, setLocalPairs] = useState(initialPairs || []);
  const [invalidInput, setInvalidInput] = useState(false);
  const [autocompletePairs] = useState(suggestions || []);
  const [keys, setKeys] = useState([]);
  const [valuesMap, setValuesMap] = useState({});
  const handleAddPair = () => {
    setLocalPairs([...localPairs, { [keyTitle]: "", [valueTitle]: "" }]);
  };

  const handleRemovePair = (index) => {
    const newPairs = [...localPairs];
    newPairs.splice(index, 1);
    setLocalPairs(newPairs);
  };

  const handlePairChange = (index, field, value) => {
    const newPairs = [...localPairs];
    newPairs[index][field] = value;
    setLocalPairs(newPairs);
  };

  const handleSave = () => {
    setPairs(localPairs);
    setFile(localPairs);
  };

  useEffect(() => {
    if (file && file.length > 0) {
      if (
        !Array.isArray(file) ||
        !file.every((obj) => typeof obj === "object")
      ) {
        setInvalidInput(true);
        return;
      }

      const transformedPairs = file.map((obj) => {
        const [keyFile, valueFile] = Object.values(obj);
        return { [keyTitle]: keyFile, [valueTitle]: valueFile };
      });

      setLocalPairs(transformedPairs);
      setInvalidInput(false);
    }
  }, [file, keyTitle, valueTitle]);

  React.useEffect(() => {
    // Extract keys and values for autocomplete and dropdown
    const keys = suggestions.map((pair) => pair[keyTitle]);
    const valuesMap = suggestions.reduce((acc, pair) => {
      acc[pair[keyTitle]] = pair[valueTitle];
      return acc;
    }, {});
    setKeys(keys);
    setValuesMap(valuesMap);
  }, [suggestions, keyTitle, valueTitle, autocompletePairs]);

  return (
    <Grid container direction="column" spacing={2}>
      {localPairs.map((pair, index) => (
        <Grid item key={index} container spacing={1}>
          <Grid item xs={5}>
            <Autocomplete
              options={keys}
              getOptionLabel={(option) => option}
              value={pair[keyTitle] || ""}
              onChange={(event, newValue) =>
                handlePairChange(index, keyTitle, newValue || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={field1Title || keyTitle}
                  fullWidth
                />
              )}
              freeSolo
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="simple-select-label">
                {field2Title || "value"}
              </InputLabel>
              <Select
                value={pair[valueTitle] || ""}
                onChange={(e) =>
                  handlePairChange(index, valueTitle, e.target.value)
                }
                fullWidth
                disabled={!pair[keyTitle] || !valuesMap[pair[keyTitle]]}
                label={field2Title || "value"}
              >
                {(valuesMap[pair[keyTitle]] || []).map((option, idx) => (
                  <MenuItem key={idx} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {index > 0 && (
            <Grid item xs={2} sx={{ display: "flex" }}>
              <IconButton onClick={() => handleRemovePair(index)}>
                <CancelIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid item container direction="row" spacing={1}>
        <Grid item xs={5}>
          <Button
            onClick={handleAddPair}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add {title}
          </Button>
        </Grid>

        <Grid item xs={5}>
          <Button onClick={handleSave} variant="outlined" startIcon={<Save />}>
            Apply
          </Button>
        </Grid>
      </Grid>

      {invalidInput && (
        <Grid item>
          <Typography sx={{ color: "red" }}>
            Invalid input. Please upload a valid format as specified in template
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default KeyValuePairsGrid;
