import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InfoCard from "../../components/cards/info-card";

import baseStyles from "../../css/const";
import Paper from "@mui/material/Paper";
import { Button, Card, Hidden } from "@mui/material";
import { Link } from "react-router-dom";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { renderVideo } from "../../components/cards/video";
import { ExampleCode } from "./tool";
import LargeLayout from "../../components/layouts/large-layout";
import TOC from "../../components/misc/table-of-content";
import ToolService from "../../services/api/tool-service";
import _ from "lodash";
import DOMPurify from "dompurify";

function TopicBox(props) {
  const classes = baseStyles();

  return (
    <React.Fragment>
      <Grid item style={{ width: "100%" }}>
        <Paper
          id={props.link.replace("/", "")}
          className={`${classes.paper} toc-anchor`}
          variant={"outlined"}
          style={{ width: "100%" }}
        >
          <Grid container justifyContent="left" spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                {props.title}
              </Typography>
            </Grid>

            <Grid item xs={12} ms={12} md={12}>
              <Typography
                align="left"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.text),
                }}
              />
            </Grid>
            {props.pypi_installation && (
              <Grid item xs={12} md={12}>
                <ExampleCode
                  source={
                    " ```python\n" + `${props.pypi_installation}\n` + "```"
                  }
                  text
                />
              </Grid>
            )}

            <Grid item xs={"auto"}>
              <Button
                component={Link}
                variant="contained"
                className={classes.button}
                to={`/tools/${props.link}`}
              >
                More Information
              </Button>
            </Grid>

            {props.code && (
              <Grid item xs={12}>
                <ExampleCode source={props.code} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="left" spacing={3}>
                {/* note that TopicBox is being used for rendering all tools but only GUI overview has a video if we have
                another tool having a video(s) we need to update here! */}
                {props.videos &&
                  props.videos.map(function (video) {
                    return (
                      <Grid item xs={12} sm={12} md={6}>
                        <Card
                          className={classes.paper}
                          variant={"outlined"}
                          style={{ width: "100%" }}
                        >
                          {renderVideo(video)}
                        </Card>
                      </Grid>
                    );
                  })}
                {props.tutorials.map(function (item) {
                  const paperProps = {
                    title: `Tutorial ${item.getting_started_order}: ${item.title}`,
                    link: `/tutorials/${item.link}`,
                    description: item.description,
                    bg_image: item.background_image,
                  };

                  return (
                    <Grid item xs={12} sm={6} md={6}>
                      <InfoCard {...paperProps} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default function GettingStarted() {
  useDocTitle("Getting Started");
  useTracker();

  const [tools, setTools] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    ToolService.getTools(null, true)
      .then((response) => {
        // sort by getting_started_order
        const _tools = _.orderBy(
          response.results,
          ["getting_started_order"],
          ["asc"]
        );
        setTools(_tools);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const classes = baseStyles();

  // * for now replace short_title with title
  const tocHeadings = tools.map((content) => {
    return { id: content.href, text: content.short_title || content.title };
  });

  if (loading) {
    return null;
  }

  return (
    <body className={classes.body}>
      <LargeLayout>
        <main>
          <Grid container justifyContent="center">
            {/* content starts */}
            <Grid item container justifyContent="center" xs={12} md={9} spacing={2}>
              <Grid item style={{ width: "100%" }}>
                <Paper
                  className={classes.paper}
                  variant={"outlined"}
                  style={{ width: "100%" }}
                >
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                      <Typography className={classes.subtitle}>
                        Getting Started
                      </Typography>
                    </Grid>

                    <Grid item xs={12} ms={12} md={12}>
                      <Typography align="left">
                        Along with our benchmark, we provide a range of tools
                        each focusing on different aspects of motion planning
                        for autonomous vehicles. To install all tools which are
                        currently released on PyPi, please run:
                        <Grid item xs={12} md={12}>
                          <ExampleCode
                            source={
                              " ```python\n" +
                              `pip install commonroad-all\n` +
                              "```"
                            }
                            text
                          />
                        </Grid>
                        To install the tools individually, please check out the
                        tool pages on our&nbsp;
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://pypi.org/user/commonroad/"
                        >
                          PyPi Profile.
                        </a>
                        <br />
                        <br />
                        The following tools are currently not available on PyPi;
                        please refer to the individual tool pages on this
                        website for the installation instructions:
                        <ul style={{ marginTop: "1rem" }}>
                          <li>CommonRoad Scenario Designer</li>
                          <li>CommonRoad Reinforcement Learning</li>
                          <li>CommonRoad Search</li>
                        </ul>
                        Below, we provide <b> minimal examples </b> and{" "}
                        <b> entry-level tutorials </b> to ease getting started
                        with using the tools. For more advanced tutorials and
                        further information about the tool, please have a look
                        at the corresponding tool pages.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {tools.map((tool) => {
                // tool might not have any tutorials associated with itself, at all
                const tutorials = _.chain(tool.tutorials)
                  .orderBy("getting_started_order", ["asc"])
                  .groupBy("getting_started")
                  .value()["true"];
                return (
                  <TopicBox
                    key={tool.href}
                    link={tool.href}
                    title={tool.title}
                    text={tool.getting_started_text || tool.text}
                    pypi_installation={tool.pypi_installation}
                    videos={tool.videos}
                    tutorials={tutorials || []}
                    code={tool.code}
                  />
                );
              })}
            </Grid>
            {/* content ends */}
            <Hidden lgDown>
              <Grid item md={3}>
                <TOC headings={tocHeadings} />
              </Grid>
            </Hidden>
          </Grid>
        </main>
      </LargeLayout>
    </body>
  );
}
