import { Typography } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import DefaultLayout from "../../components/layouts/default-layout";
import competitionAnnouncement from "../../assets/markdowns/challenge-create.md";
import remarkGfm from 'remark-gfm'


export default function ChallangeCreate() {
  
  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    fetch(competitionAnnouncement)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <DefaultLayout>
      <ReactMarkdown
        children={markdown}
        remarkPlugins={[remarkGfm]}
        escapeHtml={false}
      />
    </DefaultLayout>
  );
}
