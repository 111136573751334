import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import {GrTrophy, GrUpload, GrFolder} from "react-icons/gr";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  
  listItem :{
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    textDecoration: 'none',
    outline: '0 !important'
  },

  listItemIcon: {
    minWidth: '30px'
  }
}));
  
export default function QuickAccess() {

  const classes = useStyles();
  return (
    <Paper>
      <List>
        <ListItem className={classes.listItem} button component={Link} to={'/new-submission'}>
          <ListItemIcon className={classes.listItemIcon}><GrUpload /></ListItemIcon>
          <ListItemText> New Submission </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component={Link} to={'/user/submissions'}>
          <ListItemIcon className={classes.listItemIcon}><GrFolder /></ListItemIcon>
          <ListItemText> My Submissions </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component={Link} to={'/challenges'}>
          <ListItemIcon className={classes.listItemIcon}><GrTrophy /></ListItemIcon>
          <ListItemText> Challenges </ListItemText>
        </ListItem>
      </List>
    </Paper>
  )
}