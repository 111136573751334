import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MailIcon from "@mui/icons-material/Mail";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import DoneIcon from "@mui/icons-material/Done";

import DefaultLayout from "../../components/layouts/default-layout";
import UserService from "../../services/api/user-service";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  successPaper: {
    backgroundColor: theme.palette.success.light,
    width: "50%",
    margin: "0 auto",
    marginTop: "30px",
  },
}));

export default function ReActivate() {
  const classes = useStyles();

  // mandatory field
  const [email, setEmail] = React.useState(null);

  // status
  const [emailStatus, setEmailStatus] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonDisabled(true);

    if (email) {
      UserService.resendActivation(email)
        .then((response) => {
          setSuccess(true);
        })
        .catch((error) => {
          setSuccess(false);
          setButtonDisabled(false);
          if ("email" in error.data) setEmailStatus(error.data.email[0]);
        });
    }
  };

  return (
    <DefaultLayout>
      {!success ? (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <MailIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Resend Activation Link
            </Typography>

            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={emailStatus}
                    helperText={emailStatus ? emailStatus : null}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    inputProps="email"
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!email || buttonDisabled}
              >
                resend activation link
              </Button>
            </form>
          </div>
        </Container>
      ) : (
        <Grid container justifyContent="center" align="center" alignItems="center">
          <Grid item xs={12}>
            <DoneIcon htmlColor="green" style={{ fontSize: "400%" }}></DoneIcon>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Congratulations, we've send the activation link to <b>{email}</b> !
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              To finish up your registration process, click the link in the email we have just sent you. If you don't
              receive an email within the next 30 minutes, make sure to check the above email address, your spam folder, or
              write an email to commonroad@lists.lrz.de.
            </Typography>
          </Grid>
        </Grid>
      )}
    </DefaultLayout>
  );
}
