import React from "react";
import Chart from "chart.js";
import ActivityService from "../../../../services/api/activity-service";
import ScenarioService from "../../../../services/api/scenario-service";
import StorageService from "../../../../services/local-storage/storage-service";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";

import baseStyles from "../../../../css/const";
import { Link } from "react-router-dom";
import UserSubmissionsTableContainer from "../../../../components/user-submissions/user-submissions-table-container";
import InfoIcon from "@mui/icons-material/Info";
import UserSubmissionsStatsContainer from "../../../../components/user-submissions/user-submissions-stats-container";

export default function ChallengeSubmissons(props) {
  const classes = baseStyles();

  const user = StorageService.getUserInformation();

  // check if activityId in props
  const activityId_ = (props.activityId) ? props.activityId : user.activities[0];

  const [solutions, setSolutions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [fetch, setFetch] = React.useState(false);

  // table states
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // activity select state and select options
  const [activityId, setActivityId] = React.useState(activityId_);
  const [activityOptions, setActivityOptions] = React.useState([]);
  const [activityDocker, setActivityDocker] = React.useState(null);

  // user's rank for a challenge
  const [rank, setRank] = React.useState(-1);

  // chart reference and object
  const [chart, setChart] = React.useState(null);
  const chartRef = React.useRef();

  // first populate activity options for the select menu
  React.useEffect(() => {
    const promises = user.activities.map((activity) => {
      return ActivityService.getActivity(activity)
        .then((response) => {

          if (response.data.id === activityId) {
            setActivityDocker(response.data.hidden);
          }
            
          return {
            id: response.data.id,
            name: response.data.title,
            docker: response.data.hidden,
          };
        })
        .catch(() => {
          return {
            id: activity,
            name: null,
          };
        });
    });

    Promise.all(promises).then((responses) => {
      setActivityOptions(responses);
    });
  }, []);


  // solutions for the table
  React.useEffect(() => {
    if (user.activities.length === 0) {
      setLoading(false);
      return;
    }

    // wait until activityDocker field is set
    if (activityDocker === null)
      return;

    const params = {
      page: page + 1,
      page_size: rowsPerPage,
    };

    ActivityService.getActivityUserBenchmarks(activityId, user.id, params, activityDocker)
      .then((response) => {
        setCount(response.data.count);
        setSolutions(response.data.results);
        setLoading(false);

        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }, [page, rowsPerPage, fetch, activityId, activityDocker]);

  // user's rank for the challenge
  React.useEffect(() => {
    if (user.activities.length === 0) {
      return;
    }

    const params = {
      page: 1,
    };

    ActivityService.getFullActivityRanking(activityId, params, activityDocker).then(
      (response) => {
        const _rank = response.findIndex((x) => x.username === user.username);
        setRank(_rank + 1);
      }
    );
  }, [activityId, activityDocker]);

  // for chart
  React.useEffect(() => {
    if (user.activities.length === 0) {
      return;
    }

    // wait until docker field is set
    if (activityDocker || activityDocker === null) return;

    ScenarioService.getAllScenarioIds({ activities__id: activityId })
      .then((response) => {
        return response.data["scenario_ids"].length;
      })
      .then((num_scenarios) => {
        return ActivityService.getActivity(activityId).then((response) => {
          return (
            num_scenarios *
            response.data.cost_functions.length *
            response.data.vehicle_types.length *
            response.data.vehicle_models.length
          );
        });
      })
      .then((totalBenchs) => {
        return ActivityService.getActivityUserBenchmarks(activityId, user.id, activityDocker).then((response) => {
          const solutions = response.data.results;
          const solvedBenchs = solutions.filter((solution) => solution.evaluation_status === "success").length;

          return {
            solved: solvedBenchs,
            total: totalBenchs,
          };
        });
      })
      .then((chartValues) => {
        const _chartRef = chartRef.current.getContext("2d");

        if (chart !== null) {
          chart.destroy();
        }

        const newChart = new Chart(_chartRef, {
          type: "doughnut",
          data: {
            labels: ["Solved Benchmarks", "Unsolved Benchmarks"],
            datasets: [
              {
                label: "Scenarios",
                data: [chartValues.solved, chartValues.total - chartValues.solved],
                backgroundColor: ["rgba(0,111,60)", "rgba(191,33,47)"],
              },
            ],
          },
          options: {},
        });

        setChart(newChart);
      });
  }, [activityId]);

  const onChangeActivity = (event) => {
    const _activityId = event.target.value;
    setActivityId(_activityId);

    // find the activity from options
    const activity = activityOptions.find((x) => x.id === _activityId);
    setActivityDocker(activity.docker);
  };

  const containerProps = {
    loading: loading,
    fetch: fetch,
    solutions: solutions,
    activity: activityId,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    setLoading: setLoading,
    setFetch: setFetch,
    setPage: setPage,
    setRowsPerPage: setRowsPerPage,
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <FormControl variant="standard" fullWidth>
            <Select variant="standard" value={activityId} onChange={onChangeActivity}>
              {activityOptions.map((activity) => (
                <MenuItem key={activity.id} value={activity.id} docker={activity.docker}>
                  {activity.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Button
            className={classes.button}
            component={Link}
            to={`/challenges/id/${activityId}`}
          >
            Go to Challenge Page
          </Button>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography>
            <b>Your current rank in the challenge:</b> {rank === 0
            ? "You haven't published any solution, yet."
            : `${rank}`}
          </Typography>
        </Grid>
          {activityDocker ?
          <Grid
            item
            sm={5} xs={12}
            component={Paper}
            variant={"outlined"}
            style={{backgroundColor: "#f5f5f7", margin: "10px"}}
            className={classes.paper}
          >
            <InfoIcon/> Submissions are ranked by the number of <i>Top 1 solutions</i> with the lowest costs per
            scenario. Only public submissions are considered for the rank.
          </Grid>
          :
          null
          }

        {!activityDocker ?
          <Grid item xs={12} sm={6}>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                width: "50%",
                margin: "0 auto",
              }}
            >
              <canvas id="myChart" ref={chartRef} />
            </div>
          </Grid>
        :
          <Grid item xs={12}>
            <Typography style={{marginBottom: "15px"}}>
              <b>Select submissions for a comparison in the histogram:</b>
            </Typography>
            <UserSubmissionsStatsContainer docker={activityId}/>
          </Grid>
        }

        <Grid item xs={12}>
          <UserSubmissionsTableContainer docker={activityDocker} {...containerProps} />
        </Grid>

      </Grid>

    </React.Fragment>
  );
}
