import React from "react";
import Chart from "chart.js";
import _ from "lodash";
import { Checkbox, Grid, MenuItem, Select } from "@mui/material";
import ActivityService from "../../services/api/activity-service";

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const createHistogram = (array, bins) => {
  let buckets = _.fill(Array(bins.length - 1), 0);

  for (const element of array) {
    for (let idx = 0; idx < bins.length - 1; idx++) {
      let inside = false;
      if (idx === bins.length - 2) {
        inside = element >= bins[idx] && element <= bins[idx + 1];
      } else {
        inside = element >= bins[idx] && element < bins[idx + 1];
      }
      if (inside) {
        buckets[idx]++;
        continue;
      }
    }
  }

  return buckets;
};

const group = (data) => {
  // key-value pairs
  // data is array
  let groups = {};
  let costFunctions = [];
  for (const datum of data) {
    for (const key in datum) {
      if (datum[key] === null) {
        continue;
      }

      costFunctions = _.concat(costFunctions, key);
      if (key in groups) {
        groups[key] = _.concat(groups[key], datum[key]);
      } else {
        groups[key] = [datum[key]];
      }
    }
  }

  return [groups, costFunctions];
};

const decideBins = (array, numBins) => {
  const max = _.ceil(_.max(array));
  const min = _.floor(_.min(array));

  if (max === min) {
    return [min, max + 1];
  }

  const step = (max - min) / numBins;
  let bins = _.range(min, max + step, step);

  // if step is bigger than 1
  // ceil the numbers
  if (step >= 1) {
    bins = bins.map((bin) => _.ceil(bin));
  }

  return bins;
};

const toString = (_url, _date) => {
  const date = new Date(_date).toLocaleString("de-DE");
  return _url + " (" + date + ")";
};

export default function UserSubmissionsStatsContainer(props) {
  const chartRef = React.useRef();
  const [chart, setChart] = React.useState(null);

  const [selectedDatasets, setSelectedDatasets] = React.useState([]);

  const [costFunctions, setCostFunctions] = React.useState([]);
  const [selectedCostFunction, setSelectedCostFunction] = React.useState("TOTAL");

  const [histograms, setHistograms] = React.useState([]);
  const [bins, setBins] = React.useState([]);

  const [data, setData] = React.useState({}); // contains raw data

  React.useEffect(() => {
    ActivityService.getActivityDockerSubmissionStats(props.docker)
      .then((result) => {
        let data = result.data.data;
        let info = result.data.info;

        //* for debugging purposes
        // for (const [key, value] of Object.entries(data)) {
        //   for (let i = 0; i < 5; i++) {
        //     data[`${key}#${i}`] = value;
        //     info[`${key}#${i}`] = info[key];
        //   }
        // }

        let costFunctions = [];
        let groupedData = {};
        for (let key in data) {
          const group_ = group(data[key]);

          groupedData[key] = {
            group: group_[0],
            color: getRandomColor(),
            info: info[key],
          };

          costFunctions = new Set([...group_[1], ...costFunctions]);
        }

        setCostFunctions(Array.from(costFunctions));
        setData(groupedData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [props.docker]);

  console.log(data);

  React.useEffect(() => {
    if (Object.keys(data).length === 0) {
      return;
    }

    // the compute bin limits
    let array = [];
    for (const key of selectedDatasets) {
      array = _.concat(array, data[key]["group"][selectedCostFunction]);
    }

    // decide bins
    const bins = decideBins(array, 10);
    setBins(bins);

    // create histograms
    let histograms = [];
    for (const key of selectedDatasets) {
      // create a histogram
      const dataset = data[key]["group"][selectedCostFunction];
      const histogram = createHistogram(dataset, bins);
      const dockerURL = data[key].info.docker_url;
      const dockerDate = data[key].info.created;
      const barSeries = {
        data: histogram,
        label: toString(dockerURL, dockerDate),
        stack: key,
        backgroundColor: data[key]["color"],
      };

      histograms = _.concat(histograms, barSeries);
    }

    setHistograms(histograms);
  }, [selectedCostFunction, selectedDatasets, data]);

  React.useEffect(() => {
    if (Object.keys(data).length === 0) {
      return;
    }
    // destroy the chart object before creating a new one
    if (chart !== null) {
      chart.destroy();
    }

    const _chartRef = chartRef.current.getContext("2d");

    const newChart = new Chart(_chartRef, {
      type: "bar",
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          callbacks: {
            title: function (item) {
              return;
            },
          },
        },
        scales: {
          x: { stacked: true },
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Number of Solutions",
              },
            },
          ],
          xAxes: [
            {
              display: false,
              ticks: {
                max: bins[bins.length - 2],
              },
            },
            {
              scaleLabel: {
                display: true,
                labelString: "Costs",
              },
              display: true,
              ticks: {
                max: bins[bins.length - 1],
              },
            },
          ],
        },
      },

      data: {
        labels: bins,
        datasets: histograms,
      },
    });

    setChart(newChart);
  }, [histograms, bins]);

  const handleOnChangeSelectedDatasets = (event) => {
    setSelectedDatasets(event.target.value);
  };

  const handleOnChangeSelectedCostFunction = (event) => {
    setSelectedCostFunction(event.target.value);
  };

  if (Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Select
            variant="standard"
            multiple
            value={selectedDatasets}
            renderValue={(selected) => `${selected.length} selected`}
            onChange={handleOnChangeSelectedDatasets}
            style={{ minWidth: "100%" }}>
            {Object.keys(data).map((id) => {
              const url = data[id].info.docker_url;
              const date = data[id].info.created;

              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={selectedDatasets.includes(id)}
                    style={{
                      color: data[id].color,
                    }}
                  />
                  {toString(url, date)}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            variant="standard"
            value={selectedCostFunction}
            onChange={handleOnChangeSelectedCostFunction}>
            {costFunctions.map((costFunction) => {
              return (
                <MenuItem key={costFunction} value={costFunction}>
                  {costFunction}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>

      <div style={{ position: "relative", height: "50vh" }}>
        <canvas id="myChart" ref={chartRef} />
      </div>
    </div>
  );
}
