import React from 'react';

import { makeStyles } from '@mui/styles';
import { List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import { GrNew, GrBook, GrMail, GrGroup, GrHelp, GrTable } from "react-icons/gr";
import { Link } from 'react-router-dom';
import CompetitionsService from '../services/api/competitions-service';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },

  listItem: {
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    textDecoration: 'none',
    outline: '0 !important'
  },

  listItemIcon: {
    minWidth: '30px'
  }
}));

export const QuickAccessCompetition = ({ year }) => {
  const classes = useStyles();

  const [quickAccess, setQuickAccess] = React.useState({
    "2021": {
      active: true,
      phase1: "7b3332ab-116c-4095-9334-dbd857d89844",
      phase2: "b4d727de-0283-43e6-834d-4f5a386f2cf8",
      newsletter: "https://docs.google.com/forms/d/e/1FAIpQLSfr4p5VVKen-v-Jzg3G-NRn2oVYE8mQbhlh5c7HPJCRoUydCw/viewform",
      forum: "https://commonroad.in.tum.de/forum/c/Discuss-about-Competition-for-Motion-Planning-of-Autonomous-Vehicles-2021/"
    },
    "2022": {
      active: true,
      phase1: "0a853820-f491-4018-8c0a-6de0547d3191",
      phase2: "c436445f-de23-4fe1-8aa4-63032c25f4ef",
      newsletter: "https://forms.gle/KtdxY9iXypz6t1zM9",
      forum: "https://commonroad.in.tum.de/forum/"
    },
    "2023": {
      active: false,
      phase1: "a7cf5e8b-660d-4d56-bb53-7d352eb33a83",
      phase2: "d5a01f69-e828-436d-9f9d-dabf4226e29f",
      newsletter: "https://forms.gle/KtdxY9iXypz6t1zM9",
      forum: "https://commonroad.in.tum.de/forum/"
    },
    "2024": {
      active: false,
      phase1: "4345b16a-16b2-46e9-aa3e-6f20f73e5dae",
      phase2: "f6db9c31-4d79-4751-8019-203b6c8be1b3",
      newsletter: "https://forms.gle/KtdxY9iXypz6t1zM9",
      forum: "https://commonroad.in.tum.de/forum/"
    }
  });

  React.useEffect(() => {
    const getCompetitionInfo = async () => {
      try {
        const competition = await CompetitionsService.getInfo(null, false);
        const infos = competition.data;
        setQuickAccess(prevQuickAccess => {
          const updatedQuickAccess = { ...prevQuickAccess };
          infos.forEach(info => {
            const year = info.year;
            updatedQuickAccess[year] = {
              ...updatedQuickAccess[year],
              phase1: info.phase1,
              phase2: info.phase2
            };
          });
          return updatedQuickAccess;
        });
      } catch (error) {
        console.error("Failed to fetch competition info:", error);
      }
    };

    getCompetitionInfo();
  }, []);

  return (
    <Paper>
      <List>
        <ListItem className={classes.listItem} button component={Link} to={`/competition/${year}/announcement`}>
          <ListItemIcon className={classes.listItemIcon}><GrNew /></ListItemIcon>
          <ListItemText> Announcement </ListItemText>
        </ListItem>
        <ListItem className={classes.listItem} button component={Link} to={`/competition/${year}/details`}>
          <ListItemIcon className={classes.listItemIcon}><GrBook /></ListItemIcon>
          <ListItemText> Rules and Details</ListItemText>
        </ListItem>
        {quickAccess[year]?.active && (
          <>
            <ListItem className={classes.listItem} button component={Link} to={`/challenges/id/${quickAccess[year].phase1}`}>
              <ListItemIcon className={classes.listItemIcon}><GrTable /></ListItemIcon>
              <ListItemText> Leaderboard Phase 1</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} button component={Link} to={`/challenges/id/${quickAccess[year].phase2}`}>
              <ListItemIcon className={classes.listItemIcon}><GrTable /></ListItemIcon>
              <ListItemText> Leaderboard Phase 2</ListItemText>
            </ListItem>
          </>
        )}
        <ListItem className={classes.listItem} button component={Link} to={`/competition/${year}/organizers`}>
          <ListItemIcon className={classes.listItemIcon}><GrGroup /></ListItemIcon>
          <ListItemText> Organizers </ListItemText>
        </ListItem>
        {quickAccess[year]?.active && (
          <>
            <ListItem className={classes.listItem} button component="a" href={quickAccess[year].newsletter}>
              <ListItemIcon className={classes.listItemIcon}><GrMail /></ListItemIcon>
              <ListItemText> Newsletter </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} button component="a" href={quickAccess[year].forum}>
              <ListItemIcon className={classes.listItemIcon}><GrHelp /></ListItemIcon>
              <ListItemText> Forum </ListItemText>
            </ListItem>
          </>
        )}
      </List>
    </Paper>
  );
};
