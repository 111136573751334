import React from "react";
import ReactMarkdown from "react-markdown";

import CompetitionsService from "../../../services/api/competitions-service";

export default function CompetitionMarkdown({ year, documentType }) {
    const [markdown, setMarkdown] = React.useState("");

    React.useEffect(() => {
        CompetitionsService.getCompetitionFiles(year, documentType)
            .then((res) => { return res.data[documentType]; })
            .then((source) => {
                fetch(source)
                    .then((res) => res.text())
                    .then((text) => setMarkdown(text));
            });
    }, []);

    return (<><ReactMarkdown escapeHtml={false} children={markdown} /></>);
}
