import {
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";

export default function DynamicCheckboxForm(props) {
  const [state, setState] = React.useState(() => {
    let defaultState = {};
    props.labels.forEach((label) => {
      defaultState[label] = false;
    });
    return defaultState;
  });

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.checked,
    });
    props.onChange(e);
  };

  const error = Object.values(state).filter((v) => v).length !== 1;

  React.useEffect(() => {
    if (props.selected !== "") {
      setState((current) => {
        return { ...current, [props.selected]: true };
      });
    }
  }, [props.selected]);
  return (
    <FormControl required component="fieldset" variant="standard" error={error}>
      <FormLabel component="legend">Required</FormLabel>
      <FormGroup row={props.row}>
        {props.labels.map((label) => {
          return (
            <FormControlLabel
              label={typeof label === "string" ? label : label.label}
              control={
                <Checkbox
                  name={typeof label === "string" ? label : label.name}
                  checked={
                    typeof label === "string"
                      ? label === props.selected
                      : label.name === props.selected
                  }
                  onChange={handleChange}
                />
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
