import React from "react";

import { Typography } from "@mui/material";

export default function Sponsor2022() {
    return (
        <>
            <h3>Prize Sponsor</h3>
            <br />
            <img
                style={{ width: "200px", marginBottom: "1rem" }}
                src={
                    require("../../../../assets/images/competition-announcement/2021/IVEX_logo.png")
                        .default
                }
                alt="tutorial preview"
            />
            <h3>Acknowledgement</h3>
            <br />
            <img
                style={{ width: "200px" }}
                src={
                    require("../../../../assets/images/competition-announcement/2021/dfg_logo_englisch_blau_en.jpg")
                        .default
                }
                alt="tutorial preview"
            />
            <Typography variant="body2">
                {" "}
                For a PDF version of this call, see here: &nbsp;
                <a
                    href={
                        require("../../../../assets/pdf/competition-announcement/2022/callForSubmissions_22.pdf")
                            .default
                    }
                    target="_blank"
                >
                    download.{" "}
                </a>
            </Typography>
        </>
    );
}
