import { Container, Paper } from "@mui/material";
import React from "react";
import baseStyles from "../css/const";

export default function TabPanel(props) {
  const classes = baseStyles();

  return (
    <Paper className={classes.paper} square={false}>
      <Container disableGutters style={{ paddingTop: "10px" }}>
        {props.children}
      </Container>
    </Paper>
  );
}
