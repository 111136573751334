import getInstance from "../instances";

export default class TutorialService {
  static API_URL = process.env.REACT_APP_API_URL + "tutorial";

  static async getTutorial(link) {
    return getInstance()
      .get(`${this.API_URL}/${link}/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}

//** test build prod */
