import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@mui/material";

const ChallengeDetailsForm = ({
  title,
  setTitle,
  summary,
  setSummary,
  description,
  setDescription,
  onNextStage,
}) => {
  const [titleSubmitted, setTitleSubmitted] = useState(false);
  const [summarySubmitted, setSummarySubmitted] = useState(false);
  const [descriptionSubmitted, setDescriptionSubmitted] = useState(false);

  const handleNextStage = () => {
    setTitleSubmitted(true);
    setSummarySubmitted(true);
    setDescriptionSubmitted(true);

    if (title && summary && description) {
      onNextStage(); // Move to the next stage if all fields are filled
    }
  };

  useEffect(() => {
    setTitleSubmitted(false);
    setSummarySubmitted(false);
    setDescriptionSubmitted(false);
  }, [title, summary, description]);

  return (
    <>
      <Grid item xs={12}>
        <TextField
          required
          id="title"
          name="title"
          label="Title"
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          error={titleSubmitted && !title}
          helperText={titleSubmitted && !title ? "Title is required" : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="summary"
          name="summary"
          label="Summary"
          fullWidth
          onChange={(e) => setSummary(e.target.value)}
          value={summary}
          error={summarySubmitted && !summary}
          helperText={summarySubmitted && !summary ? "Summary is required" : ""}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="description"
          name="description"
          label="Description"
          fullWidth
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          error={descriptionSubmitted && !description}
          helperText={
            descriptionSubmitted && !description
              ? "Description is required"
              : ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleNextStage}>
          Next
        </Button>
      </Grid>
    </>
  );
};

export default ChallengeDetailsForm;
