import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";

export default function CustomCalendar(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StaticDateTimePicker
        label={props.label}
        openTo="day"
        value={moment(props.value)}
        onChange={props.onChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
