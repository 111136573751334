import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import DefaultLayout from "../../../components/layouts/default-layout";
import SlackAppService from '../../../services/slack-app';
import moment from "moment";

export default function NotFound() {

  const location = window.location.href;

  React.useEffect(() => {
    const data = {
      statusCode: 404,
      date: moment().format("MMMM Do YYYY, h:mm:ss a"),
      stack: `Page Not Found\n${location}`
    }

    SlackAppService.log(data);
  }, [])
  return (
    <DefaultLayout>
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography variant="body1" align="center">
        Looks like you have crash landed somewhere we weren't even aware of.
      </Typography>
      <Typography variant="body1" align="center">
        Check the URL once again to make sure it is valid.
      </Typography>
      <Typography variant="body1" align="center">
        Click <Link to="/">here</Link> to go to the main page.
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src="https://j.gifs.com/oZRljL.gif" alt="gif" style={{width: '100%', maxWidth: '800px'}}/>
      </div>
    </DefaultLayout>
  );
}
