import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Container,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import React from "react";
import ScenarioRow from "./scenario-row";
import ScenarioTableDropdown from "./scenario-table-dropdown";
import TableSearchField from "../misc/table-search-field";
import SortableTableHeader from "../sort/table-header";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  button: {
    marginTop: "10px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  paginationBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "4vh",
    marginTop: "2vh",
  },
  pagination: {
    "& .MuiTablePagination-toolbar": {
      paddingTop: "7.5px",
    },
  },
  showAdditionalFields: {
    marginTop: "0.75vh",
  },
}));

export default function ScenarioTable(props) {
  const classes = useStyles();

  const selectable = props.selectable || props.downloadable;
  const downloadable = props.downloadable;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;
  const count = props.count;
  const scenarios = props.scenarios || [];
  const handlePageChange = props.handlePageChange;
  const handleRowsPerPageChange = props.handleRowsPerPageChange;

  const [expandAll, setExpandAll] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selected, setSelected] = React.useState(
    props.selected ? props.selected : []
  );

  const [additionalFieldsSelected, setAdditionalFieldsSelected] =
    React.useState([]);
  const [isShowAdditionalFieldsSelected, setShowAdditionalFieldsSelected] =
    React.useState(false);

  const [selectedColumn, setSelectedColumn] = React.useState({
    col: "scenario_id",
    sort: "-",
  });

  const linkRef = React.createRef();

  /*
  Copied from scenario-row
   */
  const downloadSolution = (xml_url, scenario_id) => {
    fetch(xml_url, {
      method: "GET",
    })
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = linkRef.current;

        const regex = /^(.+\/xml\/)([a-zA-Z_\-0-9]+\.(xml|zip))/;
        const found = xml_url.match(regex);
        const fileType = found[3];

        a.download = `${scenario_id}.${fileType}`;
        a.href = href;
        a.click();
        a.href = "";
      });
  };

  const handleDownloadButtonClick = () => {
    // selected => [{scenario_id, version}]
    if (props.selected !== undefined) {
      // need to extract XML file locations
      const selected_scenarios = scenarios.filter((s) =>
        props.selected.some(
          (_selected) =>
            _selected["scenario_id"] === s["scenario_id"] &&
            _selected["version"] === s["version"]
        )
      );
      selected_scenarios.forEach((elem) =>
        downloadSolution(elem["xml"], elem["scenario_id"])
      );
    }
  };

  const handleRowSelect = (event, version) => {
    let _selected = [...selected];
    if (event.target.checked) {
      _selected.push({ scenario_id: event.target.name, version: version });
    } else {
      _selected.splice(
        _selected.findIndex(
          (x) => x.scenario_id === event.target.name && x.version === version
        ),
        1
      );
    }

    let allSelected = true;
    for (const scenario of props.scenarioIds) {
      allSelected &=
        _selected.findIndex(
          (x) =>
            x.scenario_id === scenario.scenario_id &&
            x.version === scenario.version
        ) !== -1;
    }

    props.setSelected(_selected);
    setSelectAll(allSelected);
    setSelected(_selected);
  };

  // TODO: need all scenario names
  const handleAllSelect = (event) => {
    let _selected = [...selected];
    if (event.target.checked) {
      for (const scenario of props.scenarioIds) {
        let condition = (x) =>
          (x.scenario_id === scenario.scenario_id) &
          (x.version === scenario.version);
        if (selected.findIndex(condition) === -1) {
          _selected.push(scenario);
        }
      }

      props.setSelected(_selected);
      setSelected(_selected);
      setSelectAll(true);
    } else {
      props.setSelected([]);
      setSelected([]);
      setSelectAll(false);
    }
  };

  const handleAdditionalFieldsSelect = (values) => {
    const columnDbMap = {
      "Number of Obstacles": "num_obstacles",
      "Number of Solutions": "num_solutions",
      "Average Solution Success Rate": "avg_solution_success_rate",
    };
    const out = [];
    values.forEach((value) => {
      out.push({ id: columnDbMap[value], label: value, hidden: null });
    });
    setAdditionalFieldsSelected(out);
  };

  const handleShowAdditionalFieldDropdown = () => {
    setShowAdditionalFieldsSelected(!isShowAdditionalFieldsSelected);
  };

  const sortSelectedColumn = (e) => {
    const columnDbMap = {
      "Scenario Name": "scenario_id",
      Version: "version",
      Uploaded: "created",
      "Time Horizon": "time_horizon",
      "Number of Obstacles": "num_obstacles",
      "Number of Solutions": "num_solutions",
      "Average Solution Success Rate": "avg_solution_success_rate",
    };

    let selectedField = columnDbMap[e.currentTarget.textContent];
    let sortState = "-";

    if (selectedColumn.col !== selectedField && selectedColumn.col !== "") {
      setSelectedColumn((current) => {
        return { ...current, col: selectedField, sort: "desc" };
      });
      return;
    }

    if (selectedColumn.sort === "-") sortState = "desc";
    if (selectedColumn.sort === "desc") sortState = "asc";
    if (selectedColumn.sort === "asc") sortState = "-";

    setSelectedColumn((current) => {
      return { ...current, col: selectedField, sort: sortState };
    });
  };

  const headCells = [
    { id: "scenario_id", label: "Scenario Name", hidden: null },
    { id: "version", label: "Version", hidden: "xsDown" },
    { id: "time_horizon", label: "Time Horizon", hidden: "smDown" },
    { id: "uploaded", label: "Uploaded", hidden: "smDown" },
  ];

  React.useEffect(() => {
    let allSelected = true;
    for (const scenario of props.scenarioIds) {
      allSelected &=
        selected.findIndex(
          (x) =>
            x.scenario_id === scenario.scenario_id &&
            x.version === scenario.version
        ) !== -1;
    }
    setSelectAll(allSelected);
  }, [scenarios]);

  React.useEffect(() => {
    setSelected(props.selected ? props.selected : []);
  }, [props.selected]);

  React.useEffect(() => {
    props.handleSortedScenarios(selectedColumn);
  }, [selectedColumn]);

  return (
    <React.Fragment>
      <Grid container direction={"row"} spacing={1}>
        <Grid item>
          <Button
            className={classes.button}
            variant={"contained"}
            onClick={() => setExpandAll(!expandAll)}
          >
            {expandAll ? "Collapse All" : "Expand All Rows"}
          </Button>
        </Grid>
        <Grid item>
          {downloadable && (
            <div>
              <Button
                className={classes.button}
                variant={"contained"}
                onClick={handleDownloadButtonClick}
              >
                Download Selection
              </Button>
              <a ref={linkRef} style={{ display: "none" }}>
                Download URL
              </a>
            </div>
          )}
        </Grid>
      </Grid>
      <TableContainer>
        <Container className={classes.paginationBar}>
          {isShowAdditionalFieldsSelected && (
            <ScenarioTableDropdown
              onSelectedFields={handleAdditionalFieldsSelect}
            />
          )}
          <TablePagination
            className={classes.pagination}
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  "margin-top": "1em",
                  "margin-bottom": "1em",
                },
            }}
          ></TablePagination>
          <Hidden lgDown>
            <IconButton
              className={classes.showAdditionalFields}
              aria-label="additional-field-dropdown"
              color="primary"
              onClick={handleShowAdditionalFieldDropdown}
              size="large"
            >
              <ControlPointIcon fontSize="medium" />
            </IconButton>
          </Hidden>
        </Container>

        <Table aria-label="collapsible-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderBottom: "none", paddingBottom: "0px" }}
              ></TableCell>
              {selectable && (
                <TableCell
                  style={{ borderBottom: "none", paddingBottom: "0px" }}
                  padding="checkbox"
                >
                  <Checkbox
                    data-testid="select-all"
                    checked={selectAll}
                    onChange={handleAllSelect}
                  />
                </TableCell>
              )}
              <SortableTableHeader
                fields={headCells}
                selectedColumn={selectedColumn}
                sortSelectedColumn={sortSelectedColumn}
              />
              <SortableTableHeader
                fields={additionalFieldsSelected}
                selectedColumn={selectedColumn}
                sortSelectedColumn={sortSelectedColumn}
              />
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingTop: "0px" }} />
              {selectable && <TableCell style={{ paddingTop: "0px" }} />}
              <TableCell
                colSpan={4 + additionalFieldsSelected.length}
                style={{ paddingTop: "0px" }}
              >
                <TableSearchField
                  setSearch={props.setSearch}
                  reference={props.searchRefs.scenarioName}
                />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            style={{
              position: "relative",
            }}
          >
            {
              <Backdrop
                style={{ position: "absolute", zIndex: 1, opacity: 0.3 }}
                open={props.loading}
              >
                <CircularProgress />
              </Backdrop>
            }
            {scenarios.map((scenario) => (
              <ScenarioRow
                key={`${scenario.scenario_id}-${scenario.version}`}
                {...scenario}
                expandAll={expandAll}
                selectable={selectable}
                selected={
                  selected.filter(
                    (x) =>
                      x.scenario_id === scenario.scenario_id &&
                      x.version === scenario.version
                  ).length > 0
                }
                additionalFieldsSelected={additionalFieldsSelected}
                onSelect={handleRowSelect}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{
            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                "margin-top": "1em",
                "margin-bottom": "1em",
              },
          }}
        />
      </TableContainer>
    </React.Fragment>
  );
}
