import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: "0 auto",
    marginBottom: "0px",
    backgroundColor: "white",
    boxShadow: "2px 5px 20px -8px rgba(0,0,0,0.2)",
  },
}));

export default function BlogCard(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.root} elevation={0}>
        <CardHeader title={props.title} subheader={props.subheader} />
        <CardContent>
          <Typography variant="subtitle1" style={{whiteSpace: 'pre-line'}}>{props.text}</Typography>
        </CardContent>
        <CardActions
          style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", marginTop: "auto" }}
        >
          {props.href && (
            <Button
              size="small"
              href={props.href}
              style={{ marginLeft: "auto", marginBottom: "7px", outline: "none", justifySelf: "flex-end" }}
            >
              More Information
            </Button>
          )}
        </CardActions>
      </Card>
    </React.Fragment>
  );
}
