import { Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import DefaultLayout from '../../../components/layouts/default-layout'

export default function APIError() {

  return (
    <DefaultLayout>
      <Typography variant='body1' align='center'>
        Something happened with the API calls, and support team have been notified about that
      </Typography>
      <Typography variant='body1' align='center'>
        Click <Link to='/'>here</Link> to got the main page.
      </Typography>
    </DefaultLayout>
  )
}