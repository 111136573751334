import Grid from "@mui/material/Grid";
import { DefaultPlayer as VideoRenderer } from "react-html5video";
import React from "react";

function Video(props) {
  return (
    <VideoRenderer controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen", "Res"]}>
      <source src={props.url} />
    </VideoRenderer>
  );
}

export const renderVideo = (video) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Video {...video} />
      </Grid>
    </Grid>
  );
};
