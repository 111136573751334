import React from "react";
import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import FolderIcon from "@mui/icons-material/Folder";
import * as DOMPurify from "dompurify";
import { Collapse } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";

export default function Dataset(props) {
  const [open, setOpen] = React.useState(false);
  const handleOnClick = () => {
    setOpen(!open);
  };

  return (
    <ListItem key={props.dataset.id}>
      <ListItemAvatar>
        <Avatar>
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={props.dataset.name}
        secondary={
          <ListItemText
            primary={
              <Typography
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.dataset.description) }}
                variant="body2"
              />
            }
            secondary={
              props.dataset.img ? (
                <React.Fragment>
                  {open ? (
                    <Button variant="text" color="primary" onClick={handleOnClick} size="small">
                      {" "}
                      show less <ExpandLess />{" "}
                    </Button>
                  ) : (
                    <Button variant="text" color="primary" onClick={handleOnClick} size="small">
                      {" "}
                      show more <ExpandMore />{" "}
                    </Button>
                  )}
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Typography variant="subtitle1" color="textPrimary">
                      Image
                    </Typography>
                    <img src={props.dataset.img} width="100%" />
                  </Collapse>
                </React.Fragment>
              ) : (
                <></>
              )
            }
          />
        }
      />
      <ListItemSecondaryAction>
        <IconButton
          href={props.dataset.href}
          download={true}
          disabled={props.disabled}
          target="_blank"
          rel="noopener noreferrer"
          size="large">
          <GetAppIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
