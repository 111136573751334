import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    textAlign: "center",
    marginTop: "auto",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[800],
  },
}));

export default function StickyFooter() {
  // TODO styling for mobile usage (text and height etc.)
  const classes = useStyles();
  return (
    <React.Fragment>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography variant="body1">
            Please post questions, bug reports, etc. in the
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_FORUM_URL}
            >
              {" "}
              forum
            </a>
            .<br />
            <React.Fragment>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Typography
                style={{
                  display: "inline",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                |
              </Typography>
              <Link to="/impressum">Impressum</Link>
              <Typography
                style={{
                  display: "inline",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                |
              </Typography>
              <Typography
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/CommonRoad"
              >
                GitHub
              </Typography>
              <Typography
                style={{
                  display: "inline",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                |
              </Typography>
              <Typography
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href="https://pypi.org/user/commonroad/"
              >
                PyPi
              </Typography>
            </React.Fragment>
          </Typography>
        </Container>
      </footer>
    </React.Fragment>
  );
}
