import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";

export default function CheckboxForm(props) {
  const checkboxLabels = props.labels;

  return (
    <React.Fragment>
      <FormGroup row={props.row}>
        {checkboxLabels.map((label) => {
          return (
            <FormControlLabel
              label={typeof label === "string" ? label : label.label}
              control={
                <Checkbox
                  name={typeof label === "string" ? label : label.name}
                  checked={typeof label === "string" ? label === props.selected : label.name === props.selected}
                  onChange={props.onChange}
                />
              }
            />
          );
        })}
      </FormGroup>
    </React.Fragment>
  );
}
