import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import LargeLayout from "../../../components/layouts/large-layout";
import ActivityService from "../../../services/api/activity-service";
import Challenge from "../../challenge/challenge";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useDocTitle from "../../../hooks/use-doc-title";

import baseStyles from "../../../css/const";
import ChallengeCreate from "./challenge-create";
import StorageService from "../../../services/local-storage/storage-service";
import useTracker from "../../../hooks/use-tracker";

function DeleteConfirm(props) {
  const text = `Are you sure you would like to delete the challenge ${props.activityName}?`;

  const open = props.open;
  const setOpen = props.setOpen;
  const user = props.user;
  const activityOptions = user.admin_activities;
  const enrolledActivities = user.activities;

  const handleConfirmClick = () => {
    setOpen(false);
    ActivityService.deleteActivity(props.activityId)
      .then((response) => {
        const newActivityOptions = activityOptions.filter(
          (item) => item.id !== props.activityId
        );
        const newEnrolledActivities = enrolledActivities.filter(
          (item) => item !== props.activityId
        );
        user.activities = newEnrolledActivities;
        user.admin_activities = newActivityOptions;
        StorageService.storeUserInformation(user);
        window.location = "/challenges/admin";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>{text}</DialogContent>

        <DialogActions>
          <Button onClick={handleConfirmClick} color="primary">
            Confirm
          </Button>
          <Button onClick={handleCancelClick} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default function ChallengeView(props) {
  const classes = baseStyles();

  useDocTitle("Challenge Admin Dashboard");
  useTracker();

  let user = StorageService.getUserInformation();
  const activityOptions = user.admin_activities;
  const [activityId, setActivityId] = React.useState(
    activityOptions.length > 0 ? activityOptions[0].id : null
  );
  const [editMode, setEditMode] = React.useState(false);
  const [deleteClicked, setDeleteClicked] = React.useState(false);

  const handleEditButtonClick = () => {
    setEditMode(!editMode);
  };

  const handleDeleteButtonClick = () => {
    setDeleteClicked(true);
  };

  return (
    <LargeLayout>
      <Grid container spacing={2} alignItems="center">
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <Select
                variant="standard"
                value={activityId}
                onChange={(event) => setActivityId(event.target.value)}
              >
                {activityOptions.map((activity) => {
                  return (
                    <MenuItem value={activity.id}>{activity.title}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              className={`${classes.button} ${classes.buttonEdit}`}
              startIcon={<EditIcon />}
              variant="contained"
              disabled={activityId === null}
              onClick={handleEditButtonClick}
            >
              Edit
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={`${classes.button} ${classes.buttonDelete}`}
              startIcon={<DeleteIcon />}
              variant="contained"
              disabled={activityId === null}
              onClick={handleDeleteButtonClick}
            >
              Delete
            </Button>
          </Grid>

          <Grid item>
            <Button
              className={`${classes.button} ${classes.buttonCreate}`}
              startIcon={<AddIcon />}
              variant="contained"
              component={Link}
              to="/challenges/admin/create"
            >
              Create New Challenge
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Have a look at the <a href={"/challenges/tutorial"}>tutorial</a> on
            hosting challenges.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {editMode ? (
            <ChallengeCreate
              id={activityId}
              edit={editMode || null}
              setEdit={setEditMode}
            />
          ) : activityId ? (
            <Challenge
              match={{ params: { challengeId: activityId } }}
              preview={props.preview}
            />
          ) : (
            <Typography variant="h6">
              {" "}
              You have created no challenges yet. Create an online challenge in
              which users can compare their motion planning algorithms using
              pre-defined scenarios.
            </Typography>
          )}
        </Grid>
      </Grid>
      {activityId && (
        <DeleteConfirm
          open={deleteClicked}
          setOpen={setDeleteClicked}
          activityId={activityId}
          activityName={
            activityOptions.find((item) => item.id === activityId).title
          }
          user={user}
        />
      )}
    </LargeLayout>
  );
}
