import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from "@mui/material";

import { Link } from "react-router-dom";
import Moment from "react-moment";
import InfoIcon from "@mui/icons-material/Info";
import Collapse from "@mui/material/Collapse";
import BenchmarkIdExplainer from "../../benchmark/benchmark-id-explainer";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "400px",
  },
  title: {
    fontWeight: "500",
    color: "#404040",
    fontSize: "1.7em",
    textAlign: "left",
    fontFamily: "Roboto",
  },
}));

function SelectConstFunction(props) {
  const [costFunction, setCostFunction] = React.useState("");

  const handleChange = (event) => {
    const route = props.history.location.pathname;
    let currCostFunction = extractCFFromBenchmarkId(props.benchmarkId);

    const modifiedRoute = route.replace(currCostFunction, event.target.value);
    props.history.push(modifiedRoute);
  };

  React.useEffect(() => {
    setCostFunction(extractCFFromBenchmarkId(props.benchmarkId));
  }, [props.benchmarkId]);

  const extractCFFromBenchmarkId = (benchmarkId) => {
    return benchmarkId.split(":")[1];
  };

  const COST_FUNCTIONS = ["JB1", "SA1", "WX1", "SM1", "SM2", "SM3", "TR1"];
  return (
    <Box sx={{ maxWidth: 120, marginLeft: "1.75vw", marginTop: "1vh" }}>
      <FormControl variant="standard" style={{ width: "120px" }}>
        <InputLabel id="cost-function-select-label">Cost Function</InputLabel>
        <Select
          variant="standard"
          labelId="cost-function-label"
          id="cost-function-selector"
          value={costFunction}
          label="Cost Function"
          onChange={handleChange}
        >
          {COST_FUNCTIONS.map((costFunction) => {
            return <MenuItem value={costFunction}>{costFunction}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default function BenchmarkRankingsTable(props) {
  const classes = useStyles();
  const [showBenchmarkHelp, setShowBenchmarkHelp] = React.useState(false);
  const history = useHistory();
  // AUTH_CHECK with xml  => if admin I assume that xml property is not NULL
  const isAdmin = props.solutions[0]?.xml === null ? false : true;

  const [selectAll, setSelectAll] = React.useState(false);
  const [selected, setSelected] = React.useState(isAdmin ? [] : null);

  const linkRef = React.createRef();

  const downloadSolution = (user_id, xml_url) => {
    fetch(xml_url, {
      method: "GET",
    })
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = linkRef.current;

        const regex = /^(.+\/xml\/)([a-zA-Z_\-0-9]+\.(xml|zip))/;
        const found = xml_url.match(regex);
        const fileType = found[3];

        a.download = `${user_id}.${fileType}`;
        a.href = href;
        a.click();
        a.href = "";
      });
  };

  const handleDownloadButtonClick = () => {
    // selected => [{user_id, xml}]
    if (selected.length !== 0) {
      selected.forEach((elem) =>
        downloadSolution(elem["user_id"], elem["xml"])
      );
    }
  };

  const handleRowSelect = (event, xml) => {
    let _selected = [...selected];
    if (event.target.checked) {
      _selected.push({ user_id: event.target.name, xml: xml });
    } else {
      _selected.splice(
        _selected.findIndex(
          (x) => x.user_id === event.target.name && x.xml === xml
        ),
        1
      );
    }

    let allSelected = true;
    for (const solution of props.solutions) {
      let curr_user_id = solution.user.id;
      allSelected &=
        _selected.findIndex(
          (x) => x.user_id === curr_user_id && x.xml === solution.xml
        ) !== -1;
    }
    setSelectAll(allSelected);
    setSelected(_selected);
  };

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
  };

  const handleAllSelect = (event) => {
    let _selected = [...selected];
    if (event.target.checked) {
      for (const solution of props.solutions) {
        let curr_user_id = solution.user.id;
        let condition = (x) =>
          (x.user_id === curr_user_id) & (x.xml === solution.xml);
        if (selected.findIndex(condition) === -1) {
          _selected.push({ user_id: curr_user_id, xml: solution.xml });
        }
      }
      setSelected(_selected);
      setSelectAll(true);
    } else {
      setSelected([]);
      setSelectAll(false);
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid
          container
          overflow="scroll"
          direction="column"
          alignItems="stretch"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} md={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography
                    className={classes.title}
                    style={{ paddingLeft: "20px" }}
                  >
                    Benchmark ranking
                    <br />
                    <Box fontFamily="monospace" display="inline">
                      {props.benchmarkId}
                    </Box>
                  </Typography>
                  <Grid container direction={"column"} spacing={1}>
                    <SelectConstFunction {...props} history={history} />
                    {isAdmin && (
                      <Grid item>
                        <Button
                          className={`${classes.button}`}
                          variant={"contained"}
                          sx={{ marginLeft: "1vw", marginTop: "0.75vh" }}
                          onClick={handleDownloadButtonClick}
                        >
                          Download selection
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item>
                  <IconButton
                    variant="contained"
                    onClick={() => setShowBenchmarkHelp(!showBenchmarkHelp)}
                    style={{
                      marginLeft: "10px",
                      marginRight: "auto",
                      display: "block",
                      alignItems: "left",
                      fontSize: "medium",
                    }}
                    size="large"
                  >
                    {showBenchmarkHelp ? "Hide" : "Show"} benchmark ID details
                    &nbsp;
                    <InfoIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              style={{
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {props.bestSolution &&
              props.bestSolution.current &&
              props.bestSolution.current.gif ? (
                <div>
                  <Typography
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      align: "center",
                      fontSize: "small",
                      color: "gray",
                    }}
                  >
                    Best solution:
                  </Typography>
                  <img
                    alt="Best Solution"
                    className={classes.img}
                    key={props.bestSolution.current.gif}
                    src={props.bestSolution.current.gif}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={showBenchmarkHelp}>
              <BenchmarkIdExplainer benchmarkId={props.benchmarkId} />
            </Collapse>
          </Grid>

          <Grid item>
            <TablePagination
              component="div"
              count={props.count}
              page={props.page}
              onPageChange={props.onChangePage}
              rowsPerPage={props.rowsPerPage}
              onRowsPerPageChange={props.onChangeRowsPerPage}
              sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                  {
                    "margin-top": "1em",
                    "margin-bottom": "1em",
                  },
              }}
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: "none", paddingBottom: "0px" }}
                    ></TableCell>
                    {isAdmin && (
                      <TableCell
                        style={{ borderBottom: "none", paddingBottom: "0px" }}
                        padding="checkbox"
                      >
                        <Checkbox
                          data-testid="select-all"
                          checked={selectAll}
                          onChange={handleAllSelect}
                        />
                      </TableCell>
                    )}
                    <TableCell>Rank</TableCell>
                    <TableCell>User</TableCell>

                    {/** hidden for small screens */}
                    <Hidden lgDown>
                      <TableCell>Country</TableCell>
                      <TableCell>Organization</TableCell>
                      <TableCell>Method</TableCell>
                      <TableCell>Algorithm</TableCell>
                    </Hidden>

                    <TableCell>Date</TableCell>

                    {/** hidden for small screens */}
                    <Hidden lgDown>
                      <TableCell>CPU</TableCell>
                    </Hidden>

                    {/** hidden for extra small screens */}
                    <Hidden mdDown>
                      <TableCell>Computation Time [s]</TableCell>
                    </Hidden>

                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  style={{
                    position: "relative",
                  }}
                >
                  {
                    <Backdrop
                      style={{ position: "absolute", zIndex: 1, opacity: 0.3 }}
                      open={props.loading}
                    >
                      <CircularProgress />
                    </Backdrop>
                  }
                  {/** table body */}
                  {props.solutions.map((solution, idx) => {
                    return (
                      <>
                        <TableRow
                          component={Link}
                          to={`/solutions/${solution.id}/details`}
                          hover
                          tabIndex={-1}
                          key={solution.id}
                          style={{ textDecoration: "none" }}
                        >
                          <TableCell style={{ paddingTop: "0px" }} />
                          {isAdmin && (
                            <>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  name={solution.user.id}
                                  onChange={(e) =>
                                    handleRowSelect(e, solution.xml)
                                  }
                                  onClick={handleCheckboxClick} // Stop event propagation here
                                  checked={
                                    selected.filter(
                                      (x) =>
                                        x.user_id === solution.user.id &&
                                        x.xml === solution.xml
                                    ).length > 0
                                  }
                                />
                              </TableCell>
                            </>
                          )}
                          <TableCell component="th" scope="row">
                            {props.rowsPerPage * props.page + idx + 1}
                          </TableCell>
                          <TableCell>{solution.user.username}</TableCell>
                          <Hidden lgDown>
                            <TableCell>{solution.user.country}</TableCell>
                            <TableCell>{solution.user.institution}</TableCell>
                            <TableCell>{solution.method}</TableCell>
                            <TableCell>{solution.algorithm}</TableCell>
                          </Hidden>
                          <TableCell>
                            <Moment
                              format="YYYY/MM/DD"
                              date={solution.created}
                            />
                          </TableCell>
                          <Hidden lgDown>
                            <TableCell>
                              {solution.cpu && solution.cpu.name}
                            </TableCell>
                          </Hidden>
                          <Hidden mdDown>
                            <TableCell>
                              {solution.computation_time &&
                                solution.computation_time.toFixed(3)}
                            </TableCell>
                          </Hidden>
                          <TableCell>{solution.cost.toFixed(2)}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={props.count}
              page={props.page}
              rowsPerPage={props.rowsPerPage}
              onPageChange={props.onChangePage}
              onRowsPerPageChange={props.onChangeRowsPerPage}
              sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                  {
                    "margin-top": "1em",
                    "margin-bottom": "1em",
                  },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
