import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 5 * 60 * 1000, // five minutes
  exclude: {
    query: false
  }
})


const instance = axios.create({
  adapter: cache.adapter
})

export default class ToolService {
  static API_URL = process.env.REACT_APP_API_URL + "tool/";

  static async getTools(fields = null, in_getting_started = null) {
    let _fields = null;
    if (fields) {
      _fields = fields.join(',')
    }
    return instance
      .get(this.API_URL, {
        params: {
          fields: _fields,
          in_getting_started: in_getting_started
        },
      })
      .then(async (response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static async getTool(params = null) {
    return instance.get(
      this.API_URL,
      {
        params: params
      },
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return Promise.reject(error.response);
    });
  }
}
