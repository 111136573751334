import React from "react";
import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import LaunchIcon from "@mui/icons-material/Launch";

import TabPanel from "../../components/challenge/challenge-tab-panel";
import ChallengeDateProgress from "../../components/challenge/challenge-date-progress";

import ChallengeDescription from "./tabs/challenge-description";
import ChallengeScenarios from "./tabs/challenge-scenarios";
import ChallengeLeaderboard from "./tabs/challenge-leaderboard";

import SolutionUpload from "../../components/solution/solution-upload";
import DefaultLayout from "../../components/layouts/default-layout";

import baseStyles from "../../css/const";

import ActivityService from "../../services/api/activity-service";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { Link } from "react-router-dom";
import QuickAccess from "../../components/quick-access";
import LargeLayout from "../../components/layouts/large-layout";
import StorageService from "../../services/local-storage/storage-service";
import SolutionService from "../../services/api/solution-service";

import { useHistory } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

function SubmissionButton(props) {
  const classes = baseStyles();
  const text = props.disabled ? "You can't submit solutions" : "";

  return (
    <React.Fragment>
      <Tooltip title={text}>
        <span
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Button
            startIcon={<AddIcon />}
            className={classes.button}
            style={{
              maxHeight: "30px",
            }}
            {...props}
          >
            New Submission
          </Button>
        </span>
      </Tooltip>
    </React.Fragment>
  );
}

function SubmissionRedirect(props) {
  const classes = baseStyles();

  return (
    <React.Fragment>
      <Link
        style={{
          textDecoration: "none",
          marginRight: "15px",
        }}
        to={{
          pathname: "/user/submissions",
          state: {
            activeTab: "challenge",
            activityId: props.activityId,
          },
        }}
      >
        <Button
          className={classes.button}
          startIcon={<LaunchIcon />}
          style={{
            maxHeight: "30px",
          }}
          {...props}
        >
          My challenge submissions
        </Button>
      </Link>
    </React.Fragment>
  );
}

function SubmissionDialog(props) {
  const textType = props.docker ? "docker" : "default";
  const dialogText = {
    default: (
      <React.Fragment>
        Do you want to make a new submission to this challenge? You can either
        go to <Link to="/submissions/new">New Submission</Link> page with a
        detailed explanation as to how you can make a submission or you can
        easily submit your solutions through here.
      </React.Fragment>
    ),

    docker: `You can paste the Docker Hub link of your image and submit it.`,
  };

  const [uploading, setUploading] = React.useState(false);
  const handleDialogClose = () => {
    if (!uploading) {
      props.setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={handleDialogClose}>
        <DialogTitle>Make a New Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText[textType]}</DialogContentText>
          <SolutionUpload
            docker={textType === "docker"}
            activityId={props.activityId}
            setDialogUploading={setUploading}
          />
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default function Challenge(props) {
  const classes = baseStyles();

  const history = useHistory();

  const activityId = props.match.params.challengeId;
  const previewMode = "preview" in props;
  const ParentComponent = previewMode ? React.Fragment : LargeLayout;

  const [title, setTitle] = React.useState(null);
  const [summary, setSummary] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [admins, setAdmins] = React.useState([]);
  const [vehicleModel, setVehicleModel] = React.useState(null);
  const [vehicleType, setVehicleType] = React.useState(null);
  const [costFunction, setCostFunction] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [rejected, setRejected] = React.useState(false);
  const [enrolled, setEnrolled] = React.useState(false);
  const [docker, setDocker] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [dockerURL, setDockerURL] = React.useState("");
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [redirectDisabled, setRedirectDisabled] = React.useState(false);

  const [value, setValue] = React.useState(0); // active tab
  const [loading, setLoading] = React.useState(true); //for date progress TODO: convert to ref

  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    ActivityService.getActivityDescription(activityId)
      .then((res) => {
        // get user from the local storage
        let user = StorageService.getUserInformation();
        let activities = user ? user.activities : [];

        setTitle(res.data.title);
        //setDocTitle(res.data.title);
        setSummary(res.data.summary);
        setDescription(res.data.description);
        setAdmins(res.data.users.filter((x) => x.is_admin === true));
        setVehicleModel(res.data.vehicle_models[0]);
        setVehicleType(res.data.vehicle_types[0]);
        setCostFunction(res.data.cost_functions[0]);
        setHidden(res.data.hidden);

        // parse date strings
        const startDate = Date.parse(res.data.start_date);
        const endDate = Date.parse(res.data.end_date);

        setStartDate(startDate);
        setEndDate(endDate);

        // set submit button's 'disabled' property
        setSubmitDisabled(
          !(
            startDate < Date.now() &&
            activities.includes(activityId) &&
            endDate > Date.now()
          )
        );

        setEnrolled(activities.includes(activityId));
        setDocker(res.data.hidden);

        setLoading(false);
      })
      .catch((error) => {
        history.replace(history.location.pathname, {
          errorCode: "error",
        });
      })
      .catch((error) => {
        console.log(error);
        history.replace(history.location.pathname, {
          errorCode: "error",
        });
      });
  }, [activityId, history]);

  //const [setDocTitle] = useDocTitle(`${title}`);
  useTracker();

  const handleEnrollClick = () => {
    ActivityService.enrollActivity(activityId)
      .then((res) => {
        if (res.request.status === 204) {
          // Might change this if-else statement
          let user = JSON.parse(localStorage.getItem("AUTH_USER"));
          user.activities.push(activityId);

          localStorage.setItem("AUTH_USER", JSON.stringify(user));
          setEnrolled(true);
          setSubmitDisabled(startDate > Date.now());
        } else {
          setEnrolled(false);
          setRejected(true);
        }
      })
      .catch((error) => {
        setEnrolled(false);
        setRejected(true);
      });
  };

  return (
    <ParentComponent style={{ position: "relative" }}>
      {
        <Backdrop
          style={{ position: "absolute", zIndex: 1, opacity: 0.3 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      }
      <div>
        <Typography className={classes.title} style={{ color: "#5c5c5c" }}>
          {title}
        </Typography>
        {!previewMode &&
          (!loading ? (
            enrolled ? (
              <Typography variant="body1">
                <DoneIcon htmlColor="green" />
                Enrolled
              </Typography>
            ) : !rejected ? (
              <Button
                className={classes.button}
                onClick={handleEnrollClick}
                variant="contained"
                disabled={Date.now() > endDate}
              >
                Enroll
              </Button>
            ) : (
              <Button disabled variant="contained">
                Email address not on whitelist!
              </Button>
            )
          ) : null)}
      </div>

      <div style={{ marginBottom: "20px" }}></div>

      <Grid container spacing={1}>
        <Grid item xs={10}>
          <AppBar position="static" style={{ backgroundColor: "#ffffff" }}>
            <Tabs
              value={value}
              onChange={(evt, val) => setValue(val)}
              indicatorColor="primary"
              textColor="primary"
              backgroundColor="primary"
            >
              <Tab label="Description" style={{ outline: "none" }} />
              <Tab label="Leaderboard" style={{ outline: "none" }} />
              {!hidden ? (
                <Tab label="Scenarios" style={{ outline: "none" }} />
              ) : null}
              {previewMode && (
                <Tab label="Configuration" style={{ outline: "none" }} />
              )}
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "15px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                {!previewMode && enrolled && (
                  <SubmissionRedirect
                    activityId={activityId}
                    disabled={redirectDisabled}
                  />
                )}
                {!previewMode && (
                  <SubmissionButton
                    disabled={submitDisabled}
                    onClick={() => setDialogOpen(true)}
                  >
                    New Submission
                  </SubmissionButton>
                )}
              </div>
            </Tabs>
          </AppBar>

          <div style={{ marginTop: "5px" }} />

          {value === 0 && (
            <TabPanel className={classes.challengePanel}>
              <ChallengeDescription text={description} admins={admins}>
                <ChallengeDateProgress
                  loading={loading}
                  start={startDate}
                  end={endDate}
                />
              </ChallengeDescription>
            </TabPanel>
          )}

          {value === 1 && (
            <TabPanel className={classes.challengePanel}>
              <Grid container direction={"column"} spacing={3}>
                <Grid
                  item
                  component={Paper}
                  variant={"outlined"}
                  style={{ backgroundColor: "#f5f5f7", margin: "10px" }}
                  className={classes.paper}
                >
                  <InfoIcon /> Participants are ranked by the number of Top 1
                  solutions with the lowest costs per scenario. Only public
                  submissions are listed.
                </Grid>
                <Grid item>
                  <ChallengeLeaderboard id={activityId} hidden={hidden} />
                </Grid>
              </Grid>
            </TabPanel>
          )}

          {!hidden
            ? value === 2 && (
                <TabPanel className={classes.challengePanel}>
                  <ChallengeScenarios id={activityId} />
                </TabPanel>
              )
            : null}

          {value === 3 && (
            <TabPanel>
              <Typography variant="body2">
                Vehicle Model:&nbsp;{vehicleModel}
              </Typography>
              <Typography variant="body2">
                Vehicle Type:&nbsp;{vehicleType}
              </Typography>
              <Typography variant="body2">
                Vehicle Model:&nbsp;{costFunction}
              </Typography>
            </TabPanel>
          )}

          <SubmissionDialog
            docker={docker}
            open={dialogOpen}
            setOpen={setDialogOpen}
            activityId={activityId}
          />
        </Grid>

        <Grid item xs={2}>
          <QuickAccess />
        </Grid>
      </Grid>
    </ParentComponent>
  );
}
