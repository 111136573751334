import React, { useEffect, useState } from "react";
import { Grid, TextField, IconButton, Button, Typography } from "@mui/material";
import { Add as AddIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Save } from "@material-ui/icons";

const DynamicValuesGrid = ({
  title = "Value",
  values: initialValues, // renamed to avoid conflict
  setValues,
  fieldTitle = "Field",
  checkValidInput = "email",
  file,
  setFile,
}) => {
  const [localValues, setLocalValues] = useState(initialValues || []); // Local state for values
  const [invalidInput, setInvalidInput] = useState(false);
  const [errors, setErrors] = useState([]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email validation

  const handleAddValue = () => {
    setLocalValues([...localValues, ""]);
  };

  const handleRemoveValue = (index) => {
    const newValues = [...localValues];
    newValues.splice(index, 1);
    setLocalValues(newValues);
    // Remove error for the value that is removed
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleValueChange = (index, value) => {
    const newValues = [...localValues];
    newValues[index] = value;
    setLocalValues(newValues);
  };

  const handleSave = () => {
    let valid = true;
    const newErrors = localValues.map((value) =>
      checkValidInput === "email" && !emailRegex.test(value) ? true : false
    );

    setErrors(newErrors);

    if (newErrors.includes(true)) {
      valid = false;
    }

    setInvalidInput(!valid);

    if (!valid) {
      return;
    } else {
      setValues(localValues); // Update the prop with local state
      setFile(localValues); // Assuming you want to update the file as well
    }
  };

  useEffect(() => {
    if (file && file.length > 0) {
      if (!Array.isArray(file)) {
        setInvalidInput(true);
        return;
      }

      let transformedValues =
        checkValidInput === "email"
          ? file.filter((value) => emailRegex.test(value))
          : [...file];

      setLocalValues(transformedValues); // Update local state

      if (
        !checkValidInput ||
        (checkValidInput === "email" &&
          file.every((value) => emailRegex.test(value)))
      ) {
        setInvalidInput(false);
      }
    }
  }, [file]);

  return (
    <Grid container direction="column" spacing={2}>
      {localValues.map((value, index) => (
        <Grid item key={index} container spacing={1}>
          <Grid item xs={10}>
            <TextField
              label={fieldTitle || "Value"}
              value={value || ""}
              onChange={(e) => handleValueChange(index, e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: !!value }}
              error={errors[index]}
            />
          </Grid>
          {localValues.length > 1 && (
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
              }}
            >
              <IconButton onClick={() => handleRemoveValue(index)}>
                <CancelIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid item container direction="row" spacing={1}>
        <Grid item xs={5}>
          <Button
            onClick={handleAddValue}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add {title}
          </Button>
        </Grid>

        <Grid item xs={5}>
          <Button onClick={handleSave} variant="outlined" startIcon={<Save />}>
            Apply
          </Button>
        </Grid>
      </Grid>

      {invalidInput && (
        <Grid item>
          <Typography sx={{ color: "red" }}>
            Invalid input.{" "}
            {checkValidInput === "email"
              ? "Please ensure all values are of valid email format"
              : "Please upload a valid format as specified in template"}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DynamicValuesGrid;
