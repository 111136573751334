import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import baseStyle from "../../css/const";
import tools from "../../json/tools.json";
import InfoPaper from "../../components/cards/info-card";
import Paper from "@mui/material/Paper";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";

export default function Teaching() {
  useDocTitle("Teaching");
  useTracker();

  const classes = baseStyle();

  return (
    <body className={classes.body}>
      <DefaultLayout>
        <main>
          <Paper className={classes.paper} square={true}>
            <Grid container alignItems="stretch" justifyContent="left" spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.title}>
                  Teaching Material
                </Typography>

                <Typography variant="body1" align="justify">
                  Besides benchmarking motion planners, CommonRoad is also a
                  useful tool for teaching. In our lecture, Foundations of
                  Artificial Intelligence, students solve planning problems
                  using motion primitives as a programming exercise, which can
                  be evaluated on our website. We welcome faculty and students
                  from other universities to use and modify our teaching
                  material on search algorithms, which is based on the book
                  Artificial Intelligence: A Modern Approach by Stuart Russell
                  and Peter Norvig.
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <img
                  src={require("../../assets/teaching_material.png").default}
                  width={"100%"}
                  alt="teaching_material"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>
                  Introduction and Lecture Slides
                </Typography>
              </Grid>

              <Grid item md={6} xs={6}>
                <Typography> Introduction&nbsp;to&nbsp;CommonRoad </Typography>
              </Grid>

              <Grid item md={2} xs={3}>
                <Button
                  className={classes.button}
                  startIcon={<SaveIcon></SaveIcon>}
                  variant="contained"
                  href={require("../../assets/static/LA17.pdf").default}
                  target="_blank"
                >
                  PDF
                </Button>
              </Grid>

              <Grid item md={4} xs={3}>
                <Button
                  className={classes.button}
                  startIcon={<SaveIcon></SaveIcon>}
                  color="primary"
                  variant="contained"
                  href={require("../../assets/static/Talk_IV17.zip").default}
                  target="_blank"
                >
                  LaTex
                </Button>
              </Grid>

              <Grid item md={6} xs={6}>
                <Typography>Uninformed&nbsp;search</Typography>
              </Grid>

              <Grid item md={2} xs={3}>
                <Button
                  className={classes.button}
                  startIcon={<SaveIcon></SaveIcon>}
                  color="primary"
                  variant="contained"
                  href={
                    require("../../assets/static/slides_uninformed_search.pdf")
                      .default
                  }
                  target="_blank"
                >
                  PDF
                </Button>
              </Grid>

              <Grid item md={4} xs={3}>
                <Button
                  className={classes.button}
                  startIcon={<SaveIcon></SaveIcon>}
                  color="primary"
                  variant="contained"
                  href={
                    require("../../assets/static/ArtificialIntelligence_UninformedSearch.zip")
                      .default
                  }
                  target="_blank"
                >
                  Latex
                </Button>
              </Grid>

              <Grid item md={6} xs={6}>
                <Typography>Informed search</Typography>
              </Grid>

              <Grid item md={2} xs={3}>
                <Button
                  startIcon={<SaveIcon></SaveIcon>}
                  color="primary"
                  variant="contained"
                  href={
                    require("../../assets/static/slides_informed_search.pdf")
                      .default
                  }
                  target="_blank"
                >
                  PDF
                </Button>
              </Grid>

              <Grid item md={4} xs={3}>
                <Button
                  startIcon={<SaveIcon></SaveIcon>}
                  color="primary"
                  variant="contained"
                  href={
                    require("../../assets/static/ArtificialIntelligence_UninformedSearch.zip")
                      .default
                  }
                  target="_blank"
                >
                  Latex
                </Button>
              </Grid>

              <Grid item md={6} xs={6}>
                <Typography>Programming exercise description</Typography>
              </Grid>

              <Grid item md={2} xs={3}>
                <Button
                  className={classes.button}
                  startIcon={<SaveIcon></SaveIcon>}
                  color="primary"
                  variant="contained"
                  href={
                    require("../../assets/static/online_guide_for_programming_exercise.pdf")
                      .default
                  }
                  target="_blank"
                >
                  PDF
                </Button>
              </Grid>

              <Grid item md={4} xs={3}>
                <Button
                  className={classes.button}
                  startIcon={<SaveIcon></SaveIcon>}
                  color="primary"
                  variant="contained"
                  href={
                    require("../../assets/static/online_guide_for_programming_exercise.zip")
                      .default
                  }
                  target="_blank"
                >
                  Latex
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <Grid container alignItems="stretch" justifyContent="left" spacing={3}>
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                Tutorials for Students
              </Typography>
            </Grid>

            {tools.search.tutorials.getting_started
              .concat(tools.search.tutorials.advanced)
              .map(function (item) {
                const paperProps = {
                  title: item.title,
                  link: item.link,
                  description: item.description,
                  bg_image: item.bg_image,
                };

                return (
                  <Grid item sm={12} md={6}>
                    <InfoPaper {...paperProps} />
                  </Grid>
                );
              })}

            <Grid item xs={12}>
              <Typography className={classes.subtitle}>
                Further Information
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <InfoPaper
                {...{
                  title: "Tutorial - Host a challenge on our website",
                  link: "/challenges/tutorial",
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <InfoPaper
                {...{
                  title: "Git repository",
                  link: "https://gitlab.lrz.de/tum-cps/commonroad-search",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InfoPaper
                {...{
                  title: "Forum: CommonRoad Search",
                  link: process.env.REACT_APP_FORUM_URL + "c/commonroad-search",
                }}
              />
            </Grid>
          </Grid>
        </main>
      </DefaultLayout>
    </body>
  );
}
